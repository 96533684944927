import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tryListConcepts } from "src/redux/conceptRequest/conceptRequestActionCreator";
import { Row, Col, Container, Button, Form, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk, faSearch, faChevronDown, faTrash } from "@fortawesome/free-solid-svg-icons";
import { DropdownList } from 'react-widgets';
import AppLoading from "src/components/common/AppLoading";
import ActionIcon from "src/components/general/ActionIcon";
import { useConceptFormAM } from "./hooks/useConceptFormAM";
import { shortlistedService } from 'src/utils/utils';
import { getListAllServiceAdministrativeByUserData } from "src/redux/administrativeService/administrativeServiceReducer";

const ConceptFormAM = (props) => {
  const dispatch = useDispatch();
  const { conceptToEdit, isFetching } = props;
  const {
    redirectToConceptList,
    disableButtonAssociate,
    isListed,
    conceptId,
    conceptsListOrder,
    setConceptsListOrder,
    principalDontAllowCond,
    messagesDropDown,
    handleSaveConceptAndCreateOther,
    handleSaveConcept,
    handleAsociateButton,
    handleConceptListOnChange,
    handleNewOnChange,
    handleSubConceptoOnChange,
    handleAddConceptButton,
    addConceptToList,
    handleDeleteConcept,
    conceptsList,
    conceptListData,
    concept,
    subConcepto,
    hasConceptList,
    changeSelectState,
    addNewconceptOpt,
    formik,
    administrativeServiceData,
    admServiceId,
    setAdmServiceId
  } = useConceptFormAM(conceptToEdit, dispatch, props);
  const serviceAdministrativeByUser = useSelector(state => getListAllServiceAdministrativeByUserData(state))
  const listIdServiceAdministrativeByUser = serviceAdministrativeByUser.map(i => i.code)
  const defaultParams = {
    page: 0,
    size: 10,
    administrativeServiceId: listIdServiceAdministrativeByUser
  };
  let lockDropdown = false;
  const validateActionIcon = (item) => {
    let actionIconItem = {
        text: '',
        validation: true
    };
    if(!item){
      actionIconItem.text = 'Concepto o subconceptos asociado otros registros'
      actionIconItem.validation = false;
    }
    return actionIconItem
  };
  if (typeof conceptToEdit !== 'undefined') {
    lockDropdown = true;
  }

  useEffect(() => {
    setConceptsListOrder(
      conceptsList?.sort(
        (a, b) => a.concept - b.concept || a.subConcepto - b.subConcepto
      )
    );
  }, [conceptsList]);

  useEffect(() => {
    handleAsociateButton();
  }, [concept, subConcepto]);

  useEffect(() => {
    dispatch(tryListConcepts(defaultParams))
    if(!conceptToEdit?.id && shortlistedService(administrativeServiceData)) setAdmServiceId(administrativeServiceData?.[0]?.id);
  },[]);

  return (
    <Container fluid>
      <Form onSubmit={formik.handleSubmit}>
          <span className="text-danger d-flex mandatory-label text-right font-weight-bold font-italic">
            <FontAwesomeIcon  icon={faAsterisk} className="mb-2 mr-1 text-danger mandatory-asterisk"/>
            Obligatorio
          </span>

          <Row className="my-2 text-black-color">
            <Col sm="4">
              <p className="text-right">
                <FontAwesomeIcon  icon={faAsterisk} className="mb-2 mr-1 text-danger mandatory-asterisk" />
                Servicio:
              </p>
            </Col>
            <Col sm="4">
              <DropdownList filter
                placeholder='Debe seleccionar un servicio...'
                data={administrativeServiceData}
                allowCreate='onFilter'
                name='admService'
                textField='displayName'
                valueField='id'
                value={admServiceId}
                as='select'
                className='w-100 text-black-color'
                onChange={value => setAdmServiceId(value?.id)}
                messages={messagesDropDown}
                selectIcon={<FontAwesomeIcon   icon={faChevronDown} className='text-black-color' />}
                searchIcon={<FontAwesomeIcon   icon={faSearch} className='text-black-color' />}
                disabled={lockDropdown}
              >
              </DropdownList>
            </Col>
          </Row>

          <Row className="my-2 text-black-color">
            <Col sm="4">
              <p className="text-right">
                <FontAwesomeIcon  icon={faAsterisk} className="mb-2 mr-1 text-danger mandatory-asterisk"/>
                Concepto:
              </p>
            </Col>
            <Col sm="4">
                <DropdownList
                  filter="contains"
                  as="select"
                  placeholder="Seleccione un concepto..."
                  data={conceptListData}
                  allowCreate="onFilter"
                  name="conceptId"
                  textField="displayName"
                  valueField="id"
                  disabled={(hasConceptList && changeSelectState) || lockDropdown}
                  readOnly={addNewconceptOpt || (isListed)}
                  value={!addNewconceptOpt ? conceptId : ''}
                  className="w-100"
                  onChange={(value) => handleConceptListOnChange(value)}
                  messages={messagesDropDown}
                  selectIcon={<FontAwesomeIcon   icon={faChevronDown} className="text-black-color"/>}
                  searchIcon={<FontAwesomeIcon   icon={faSearch} className="text-black-color"/>}
                ></DropdownList>
            </Col>
            <Col sm="2" >
                <Button
                  variant="success"
                  disabled={isListed || !!conceptId}
                  onClick={handleAddConceptButton}
                >
                  {!addNewconceptOpt ? "Nuevo" : "Lista"}
                </Button>
            </Col>
          </Row>

          {addNewconceptOpt && (
            <Row className="my-2 text-black-color">
              <Col sm="4">
                <p className="text-right">
                  <FontAwesomeIcon  icon={faAsterisk} className="mb-2 mr-1 text-danger mandatory-asterisk" />
                  Nuevo Concepto:
                </p>
              </Col>
              <Col sm="4">
                <input
                  name="nuevoConcepto"
                  type="text"
                  placeholder="Nuevo Concepto"
                  className="form-control"
                  onChange={handleNewOnChange}
                  disabled={isListed}
                  value={formik.values.nuevoConcepto}
                />
                {formik.errors.nuevoConcepto && (
                  <div className="text-danger">{`${formik.errors.nuevoConcepto}`}</div>
                )}
              </Col>
            </Row>
          )}

          <Row className="my-2 text-black-color">
            <Col sm="4">
              <p className="text-right">
                <FontAwesomeIcon icon={faAsterisk} className="mb-2 mr-1 text-danger mandatory-asterisk"/>
                Sub-Concepto:
              </p>
            </Col>
            <Col sm="4">
              <textarea
                name="subConcepto"
                maxLength="256"
                className="form-control"
                onChange={handleSubConceptoOnChange}
                value={formik.values.subConcepto}
              />
            </Col>
          </Row>

          <Row className="mt-3 mb-5">
              <Col sm="8" className="text-center d-flex justify-content-end">
                <Button
                  type="button"
                  disabled={disableButtonAssociate}
                  variant="success"
                  size="lg"
                  onClick={() => addConceptToList()}
                >
                  Asociar
                </Button>
              </Col>
          </Row>

        {hasConceptList && (
          <>
              <p>Sub Conceptos Asociados</p>
              <Table striped borderless hover>
                <thead className="font-weight-bold">
                  <tr>
                    <th className="text-center align-middle">SubConcepto</th>
                    <th className="text-center align-middle">Accion</th>
                  </tr>
                </thead>
                <tbody className="text-black-color">
                  {conceptsListOrder?.map((conceptItem) => {
                    let defined = conceptItem?.canDelete;
                    if (typeof defined !== 'undefined'){
                      defined = conceptItem?.canDelete;                  
                    } else {
                      defined = true;
                    }
                    return (
                      <tr key={conceptItem}>
                        <td className="text-center align-middle py-1">
                          {`${conceptItem?.description}`}
                        </td>
                        <td className="text-center align-middle py-1">
                          <ActionIcon
                            className={(!defined) ? "not-allowed" : '' }
                            disable={(!defined)}
                            id="delete"
                            toolTipText="Eliminar SubConcepto"
                            icon={faTrash}
                            onClick={() => validateActionIcon( defined ).validation && handleDeleteConcept(conceptItem)}   
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
          </>
        )}

        <Container fluid className="d-flex justify-content-center mt-5">
              <Button
                className="mx-3"
                type="button"
                variant="primary"
                size="lg"
                onClick={redirectToConceptList}
              >
                Cancelar
              </Button>
              <Button
                className="mx-3"
                type="submit"
                disabled={principalDontAllowCond || !admServiceId}
                variant="success"
                size="lg"
                onClick={handleSaveConcept}
              >
                Guardar
              </Button>
              <Button
                className="mx-3"
                hidden={conceptToEdit}
                type="button"
                disabled={principalDontAllowCond || !admServiceId}
                variant="success"
                size="lg"
                onClick={handleSaveConceptAndCreateOther}
              >
                Guardar y crear otro
              </Button>
        </Container>
      </Form>
      <AppLoading isLoading={isFetching} />
    </Container>
  );
};

export default ConceptFormAM;