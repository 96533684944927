import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFormContext, FormProvider } from 'react-hook-form';
import { Form, Col, Row, Button, Modal, Alert } from 'react-bootstrap';
import ActionIcon from 'src/components/general/ActionIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faPlus, faAsterisk, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { getAdministrativeServiceOrganismListData } from 'src/redux/administrativeService/administrativeServiceReducer';
import { tryGetOrganismAdministrativeServices } from 'src/redux/administrativeService/administrativeServiceActionCreator';
import { TIPO_RECIBO } from 'src/utils/constants';
import { getClearObject, numberFormatParser, organismsFilter, removeUnuselessObjProperties, isValidateOrganismCode, parseIntOrUndefined  } from 'src/utils/utils';
import ModalReceptionAct from 'src/components/pages/OrderPay/modal/ModalReceptionAct';
import ModalCertificacionObraPublica from 'src/components/pages/OrderPay/modal/ModalCertificacionObraPublica';
import ModalCertificadoServicios from 'src/components/pages/OrderPay/modal/ModalCertificadoServicios';
import FormFieldError from '../../general/FormFieldError';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import TableReceiptsGenerator from 'src/components/pages/OrderPay/TableReceiptsGenerator';

const ModalReceiptGenerator = (props) => {
	const {
		receipts, setReceipts, onlyRead
	} = props;
	const { orderPay, admServiceId: codeAdministrative, admCode, admNumber, admYear, } = useFormContext();
    const dispatch = useDispatch();
    const hookFormMethodsModal = {...useForm(), codeAdministrative};
	const { handleSubmit, errors } = hookFormMethodsModal;
	const periodSelected = useSelector(state => getGlobalDataSelectedPeriod(state))?.id;
	const childCompRef = useRef();
	const [ admServiceId, ] = useState(orderPay?.administrativeServiceId);
	const [codeOrganism, setCodeOrganism] = useState('');
    const [tipo, setTipo] = useState(orderPay?.beneficiaries[0].receipts[0].type);
	const hasReceipts = receipts?.length > 0;
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const messagesDropDown = { emptyFilter: 'No hay resultados', emptyList: 'No hay resultados' };
    const organismList = useSelector(state => getAdministrativeServiceOrganismListData(state));

	const [isReciptAmountValid, setIsReciptAmountValid] = useState(false);
	const isWorkCertificateValid = (tipo === 'CERTIFICADO_DE_OBRAS_PUBLICAS') ? isReciptAmountValid : true;
	const isReceptionAct = (tipo === 'ACTA_DE_RECEPCIÓN');

    const generalDescriptionObj = { required: "Debe ingresar una descripción general" };

	const organismListFilter = organismsFilter(organismList);

	const reciboValidationObj = { 
		validate: (value)=> {
			return (value && receipts?.length> 0 ) || 'Debe cargar un recibo';
		}};

	const stopPropagate = (callback)=>{
		return (e) => {
			e.stopPropagation();
			callback(e);
		};
	}

	const checkKeyDown = (e) =>{
		if (e.nativeEvent.key === 'Enter'){
			e.nativeEvent.preventDefault();
		}
	};

	const handleOrganismOnChange = (value) => {
		setCodeOrganism(value || '');
        delete errors?.codeOrganism;
    };

	const handleModalAdmServiceIdOnChange = () => {
        delete errors?.admService;
    };

	const cleanUp = () => {
		handleOrganismOnChange();
		handleModalAdmServiceIdOnChange();
	};

    const onSubmitForm = (data, indexEditing) => {
		const propertiesToFormat = ["penaltyFee"];
		const dataParse = numberFormatParser(data, propertiesToFormat);
		removeUnuselessObjProperties(data, ["ticketAmount", "tieneRegistros"]);
		const clearData = getClearObject(data);

		if(tipo === 'ACTA_DE_RECEPCIÓN'){
			const {admService, orderProvisionNumber, orderProvisionYear, provisionOrderId, initiator, ..._handleFormData} = clearData;
			const provisionOrder = provisionOrderId ? {
				administrativeServiceId: admService,
				number: orderProvisionNumber,
				year: orderProvisionYear,
				id: provisionOrderId,
				initiator: initiator
			} : (admService || orderProvisionNumber || orderProvisionYear) ? {
				administrativeServiceId: admService,
				number: orderProvisionNumber,
				year: orderProvisionYear
			} : null;
			const dto = {..._handleFormData, provisionOrder};

			if (Number.isInteger(indexEditing)){
				setReceipts(receipts.map( (i, index) => {
					if (index == indexEditing){
						return {id: i.id, ...dto, ...dataParse, tickets: props?.ticketObj?.ticketData};
					}
					return i;
				}));
			}else{
				setReceipts(prevArray => [...prevArray, {...dto, ...dataParse, tickets: props?.ticketObj?.ticketData}]);
			}
		}
		else if(tipo === 'CERTIFICADO_DE_OBRAS_PUBLICAS'){
			const dataParsed = {
				...clearData,
				penaltyFee: 0,
				authorizedLegalInstrumentId:{
					number: data.authorityNumber,
					year: data.authorityNumberYear,
					legalInstrumentType:{
						name:data.authorizedBy
					},
				},
				adjudicatedLegalInstrumentId:{
					number: data.adjudicatorNumber,
					year: data.grantedByYear,
					legalInstrumentType:{
						name:data.grantedBy
					},
				},
				priceContest:{
					type: data?.tenderType,
					number: data?.tenderNumber,
					year: data?.tenderYear
				}
			}
			const properties = ["authorityNumber", "authorityNumberYear", "authorizedBy", "adjudicatorNumber", "grantedByYear", "grantedBy", "tenderType", "tenderNumber", "tenderYear"];
			removeUnuselessObjProperties(dataParsed, properties);
			if (Number.isInteger(indexEditing)){
				setReceipts(receipts.map( (i, index) => {
					if (index == indexEditing){
						return {
							id: i.id, 
							...dataParsed, 
							tickets: props?.ticketObj?.ticketData, 
							breakDownWorkCertificate: props?.breakDownWorkCertificateObj?.amountData
						};
					}
					return i;
				}));
			}else{
				const newRecips = prevArray => [
					...prevArray, 
					{
						...dataParsed, 
						tickets: props?.ticketObj?.ticketData, 
						breakDownWorkCertificate: props?.breakDownWorkCertificateObj?.amountData 
					}
				]
				setReceipts(newRecips);
			}
		}
		else if(tipo === 'CERTIFICADO_DE_SERVICIOS'){
			const dataParsed = {
				...clearData, penaltyFee: 0
			}
			if (Number.isInteger(indexEditing)){
				setReceipts(receipts.map( (i, index) => {
					if (index == indexEditing){
						return {id: i.id, ...dataParsed, tickets: props?.ticketObj?.ticketData};
					}
					return i;
				}));
			}else{
				setReceipts(prevArray => [...prevArray, {...dataParsed, tickets: props?.ticketObj?.ticketData, }]);
			}
		}
		cleanUp();
        handleClose();
    };

    useEffect(() => {
		if (receipts?.length){
			props?.trigger('TIPO_RECIBO');
		}
    }, [receipts]);

	const renderSwitch = (tipoRecibo) => {
		let body;
		switch (tipoRecibo){
			case 'ACTA_DE_RECEPCIÓN':
				body = <ModalReceptionAct
							organismList={ organismListFilter }
							messagesDropDown={ messagesDropDown }
							handleModalAdmServiceIdOnChange={ handleModalAdmServiceIdOnChange }
							handleOrganismOnChange={ handleOrganismOnChange }
							codeOrganism={ codeOrganism }
							tipo={tipo}
							ref={childCompRef}
							ticketObj={props?.ticketObj}
							admCode={admCode}
							admNumber={admNumber}
							admYear={admYear}
						/>;
				break;
			case 'CERTIFICADO_DE_SERVICIOS':
				body = <ModalCertificadoServicios 
							organismList={organismListFilter}
							codeOrganism={codeOrganism}
							handleOrganismOnChange={handleOrganismOnChange}
							messagesDropDown={messagesDropDown}
							tipo={tipo}
							ticketObj={props?.ticketObj}
						/>;
				break;
			case 'CERTIFICADO_DE_OBRAS_PUBLICAS':
				body = <ModalCertificacionObraPublica
							tipo={tipo}
							breakDownWorkCertificateObj={props?.breakDownWorkCertificateObj}
							ticketObj={props?.ticketObj}
							organismList={organismListFilter}
							codeOrganism={codeOrganism}
							handleOrganismOnChange={handleOrganismOnChange}
							messagesDropDown={messagesDropDown}
							setIsReciptAmountValid={setIsReciptAmountValid}
						/>;
				break;

		}
		return body;
	}
    
    useEffect(() => {
		if(codeAdministrative !== '' &&  codeAdministrative !== undefined) {
			dispatch(tryGetOrganismAdministrativeServices(codeAdministrative, periodSelected));
		}
		if(orderPay !== '' && orderPay !== undefined) {
			dispatch(tryGetOrganismAdministrativeServices(orderPay.administrativeServiceId, periodSelected));
		}
		handleModalAdmServiceIdOnChange(codeAdministrative);
    }, [codeAdministrative]);

	//enable receipt record 
	const enableAddReceipt = (isValidateOrganismCode(admCode) && admNumber && Number.isInteger(parseIntOrUndefined(admYear))) ? true : false;

    useEffect(() => {
        if(!orderPay?.id && isReceptionAct) setReceipts([])
    }, [admCode, admNumber, admYear]);

    return <>
        <Modal
			show={show}
			onHide={handleClose}
			scrollable={true}
			dialogClassName="modal-xl"
			backdrop="static"
			keyboard={false}
		>
			<Modal.Header closeButton>
				<Modal.Title>{TIPO_RECIBO[tipo]}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			<FormProvider {...hookFormMethodsModal } >
				<Form 
					className='mt-4 text-black-color' 
					onKeyDown={(e) => checkKeyDown(e)} 
					onSubmit={stopPropagate(handleSubmit(onSubmitForm))}
				>
					{renderSwitch(tipo)}
                    <div className='d-flex justify-content-around mt-4 mb-3'>
                        <Button type='button' variant='primary' size='lg' onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button
                            type='submit'
                            variant='success'
                            size='lg'
							disabled={!isWorkCertificateValid}
						>
                            Guardar
                            <FontAwesomeIcon icon={faSave} className='ml-2 text-white-color ' />
                        </Button>
					</div>
				</Form>	            
			</FormProvider>
			</Modal.Body>
		</Modal>
        <Form.Group as={Row} className=''>
			<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
				<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
				Recibo:
			</Form.Label>
			<Col sm='4'>
				<div className='d-flex justify-content-around'>
					<Form.Control
						as='select'
						name = 'TIPO_RECIBO'
						ref={props?.register(reciboValidationObj)}
						readOnly={receipts?.length}
						onChange={event => setTipo(event?.target?.value)}
						defaultValue={orderPay?.beneficiaries[0].receipts[0].type}
					>
						<option value={''}>Seleccione un tipo de Recibo...</option>{
						Object.keys(TIPO_RECIBO)?.map( (key, idx) => <option key={idx} value={key}>
								{TIPO_RECIBO[key]}
							</option>
						)}
					</Form.Control>
				</div>
				{ tipo && !codeAdministrative && !admServiceId &&
					<Alert variant='warning' className='mt-1'>
						Debe Seleccionar un Servicio.
					</Alert>
				}
				<FormFieldError errors={props?.errors?.TIPO_RECIBO} />
			</Col>
			<Col sm={2}  className='ml-0 pl-0' >
				<ActionIcon size='lg' toolTipText="Cargar Recibo" 
					disabled={(!admServiceId ? (!tipo || !codeAdministrative) : !tipo) || (isReceptionAct && !enableAddReceipt)} 
					onClick={handleShow} 
					text='Añadir'
				/>
			</Col>
			{!hasReceipts &&
				<Col sm={4} className='offset-md-4 mt-3'>
					<Alert variant='warning'>
						<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
						Debe añadir un recibo.
					</Alert>
				</Col>
			}
			{(isReceptionAct && !enableAddReceipt) &&
				<Col sm={4} className='offset-md-4 mt-1'>
					<Alert variant='warning'>
						<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
						Ingrese datos del expediente.
					</Alert>
				</Col>
			}
		</Form.Group>

		{(receipts?.length > 1) &&
			<Form.Group as={Row} className='pt-4'>
				<Form.Label htmlFor='asunto' className='text-right d-flex mandatory-label' column sm='4'>
	                <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
					Reseña general de la OP:
				</Form.Label>
				<Col sm='4'>
					<Form.Control
						as='textarea'
						name='generalDescription'
						placeholder='Ingresar Observaciones'
						rows={2}
						ref={props?.register(generalDescriptionObj)}
						defaultValue={orderPay?.generalDescription}
						maxLength="255"
					/>
					<FormFieldError errors={errors?.generalDescription} />
				</Col>
			</Form.Group>
		}

		{ hasReceipts &&
			<TableReceiptsGenerator
				receipts={receipts}
				setReceipts={setReceipts}
				tipo={tipo}
				admCode={admCode}
				admNumber={admNumber}
				admYear={admYear}
				_hookFormMethodsModal={{
					hookFormMethodsModal, checkKeyDown, stopPropagate, onSubmitForm, 
					ticketObj:props?.ticketObj,
					breakDownWorkCertificateObj: props?.breakDownWorkCertificateObj,
					organismList:organismListFilter,
					codeOrganism,
					handleOrganismOnChange,
					handleModalAdmServiceIdOnChange,
					messagesDropDown,
					tipo
				}}
			/>
		}
    </>
};

export default ModalReceiptGenerator;