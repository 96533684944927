import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import FormFieldError from 'src/components/general/FormFieldError';
import { caption, year } from 'src/utils/label';

const CaptionForm = props => {

    const { register, errors, captionToEdit } = useFormContext();

    //validations
	const yearRequiredMsg = 'Debe ingresar el año.';
	const captionRequiredMsg = 'Debe ingresar la leyenda.';
	
	const yearValidationObj = { required: yearRequiredMsg };
	const captionValidationObj = { required: captionRequiredMsg };

	return <>
	
		<Form.Group as={Row} controlId='alias'>
			<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
				<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
					{year}:
			</Form.Label>
			<Col sm={4}>
				<Form.Control
					ref={register(yearValidationObj)}
					type='number'
					name='year'
					max='2100'
					min='1990'
					placeholder='Año'
					defaultValue={captionToEdit?.year} 
					disabled={!!captionToEdit}
				/>
				<FormFieldError errors={errors?.year} />
			</Col>
		</Form.Group>

		<Form.Group as={Row} controlId='alias'>
			<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
				<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
					{caption}:
				</Form.Label>
				<Col sm={4}>
					<Form.Control
                        ref={register(captionValidationObj)}
                        as='textarea'
                        placeholder={caption}
                        name='caption'
                        disabled={false}
                        defaultValue={captionToEdit?.caption} 
                        maxLength={1000}
				    />
				    <FormFieldError errors={errors?.caption} />
				</Col>
		</Form.Group>
		
    </>
};

export default CaptionForm;