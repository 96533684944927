import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext, useForm } from 'react-hook-form';
import { Form, Table, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormFieldError from 'src/components/general/FormFieldError';
import { faAsterisk, faExclamationTriangle, faPlus, faTimes, faSearch, faBroom, faFilter, faClipboardList, faClipboardCheck, faBan } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from 'src/components/general/ActionIcon';
import { SumCollectionAmountsOP, calcTotalImportes, formatterPeso, getClearObject, PFLabelConstructor, selectedOrderTypeToSend } from 'src/utils/utils';
import DetailModal from 'src/components/pages/FundRequests/modal/DetailModal';
import FigurativeModal from 'src/components/pages/FundRequests/modal/FigurativeModal';
import RCPagination from 'src/components/common/RCPagination';
import { getFundRequestsToEdit } from 'src/redux/fundRequests/fundRequestsReducer';
import { isNotEmptyArray } from "src/services/validationService";
import { clearListOrderPayData } from 'src/redux/orderPay/orderPayActions';
import { figurativeRegistrationCompleted, figurativeRegistrationMissing } from 'src/utils/label';
import LiquidationDetailModal from 'src/components/pages/OrderPay/modal/DetailModal';

const PayOrderListBox = props => {
	const dispatch = useDispatch();
	const { selectedFundRequestType, isFetching, tryMethod, clearMethod, metaData, dataRecords, ordenes } = props;
	const [budgetType, setBudgetType] = useState(undefined);
	const [viewSelectedItem, setViewSelectedItem] = useState(false);
	const [filteredSelectedItems, setFilteredSelectedItems] = useState([]);
	const fundRequestEditSelected = useSelector(state => getFundRequestsToEdit(state));

	const figurativeCalculationTotalAmount = (data) => {
		const figurativeData = data?.filter((item) => item?.figurativeExpenditures?.length);
		if (isNotEmptyArray(figurativeData)) {
			return figurativeData?.length > 1 ? 
			figurativeData?.map((item) => item?.figurativeExpenditures?.map(item => item?.amount)?.reduce((a, b) => a + b))?.reduce((a, b) => a + b) : 
			figurativeData?.[0]?.figurativeExpenditures?.map(item => item?.amount)?.reduce((a, b) => a + b)
		}
		return 0;
	};

	//Pagination data
	const page = metaData?.page + 1;
	const totalItemsCount = metaData?.filteredCount ? metaData?.filteredCount : '1';
	const totalItems = metaData?.filteredCount ? metaData?.filteredCount : '0';

	//Parent Form Controller
	const { admServiceId, selectedItems, setSelectedItems, itemsRelated, bankAccountId, register, errors, ordenesAsociadas, setOrdenesAsociadas } = useFormContext();
	//Parent Form Controller Validations
	const payOrderValidationObj = { required: PFLabelConstructor(selectedFundRequestType)?.required };
	const isSelectedItemValidationObj = { required: 'No puede añadir mas de 5 Ordenes de Pago por Pedido de Fondos' };

	//Form filters
	const { register: regItem, reset, handleSubmit } = useForm();
	const [filtersToSend, setFiltersToSend] = useState({});

	//Modal Figurative
	const [showFigurativeModal, setShowFigurativeModal] = useState(false);
	const [activeFundRequestDetailDto, setActiveFundRequestDetailDto] = useState(null);

	const hasItemFetched = ordenes?.length > 0;
	const hasItemsSelected = selectedItems?.length > 0;
	const hasItemsAsociados = ordenesAsociadas?.some(i => i.selected);

	const relatedAmounts = calcTotalImportes(itemsRelated);

	//Detail Modal
	const [activePayOrder, setActivePayOrder] = useState();
	const [showPayOrderDetailModal, setShowPayOrderDetailModal] = useState(false);
	const [showLiquidationDetailModal, setShowLiquidationDetailModal] = useState(false);

	const handleModalDetallePayOrder = (payOrder) => {
		setShowPayOrderDetailModal(true);
		setActivePayOrder(payOrder);
	};

	const handleLiquidationDetailModal = payOrder => {
		setActivePayOrder(payOrder);
		setShowLiquidationDetailModal(true);
	};

	const fetchList = (params) => {
		dispatch(clearMethod());
		dispatch(tryMethod(params))
	};

	const type = fundRequestEditSelected ? selectedOrderTypeToSend?.[fundRequestEditSelected?.fundRequestType] :
		selectedOrderTypeToSend?.[selectedFundRequestType];

	const pickedItems = selectedItems?.concat(ordenesAsociadas.filter(i => i.selected));
	const defaultParams = {
		page: 0,
		size: 10,
		state: 'BORRADOR',
		administrativeServiceId: fundRequestEditSelected ? fundRequestEditSelected?.administrativeServiceId : admServiceId,
		bankAccountId,
		type,
	};

	const handleCheckCondition = (item) => {
		return !selectedItems?.some(obj => obj.paymentOrder.id === item.id);
	};

	const clearFilters = () => {
		setFiltersToSend({});
		reset();
		fetchList(defaultParams);
	};

	const stopPropagate = (callback) => {
		return (e) => {
			e.stopPropagation();
			callback(e);
		};
	};

	const handleSubmitter = (params) => {
		const newFilterObject = getClearObject(params);
		if (!isFetching) {
			fetchList(newFilterObject);
		}
	};

	const onPageChange = (pNumber) => {
		const filterParams = {
			...defaultParams,
			...filtersToSend,
			page: pNumber - 1,
		};
		handleSubmitter(filterParams);
	};

	const onSubmitFormFilter = (data) => {
		const filterParams = {
			...defaultParams,
			...data
		};
		setFiltersToSend(data);
		handleSubmitter(filterParams);
	};

	const addPayOrderToSelectionList = payOrder => {
		let payOrderCopy = JSON.parse(JSON.stringify(payOrder));
		setViewSelectedItem(true)
		setBudgetType(payOrderCopy?.details[0].presupuestoResponse?.budgetType);
		let fundRequestDetailDto = {
			paymentOrder: payOrderCopy
		};
		setSelectedItems(prevArray => [...prevArray, fundRequestDetailDto]);
	}

	const removePayOrderFromSelectionList = paymentOrderDto => {
		if ((selectedItems?.length == 1) && (ordenesAsociadas.filter(item => item?.selected == true)?.length == 0)) setBudgetType('');
		setSelectedItems(selectedItems.filter(item => item.paymentOrder.id !== paymentOrderDto.id));
		delete paymentOrderDto?.fundRequestFigurativeExpenditures;
	};

	const updateAsociadas = (ordenAsociada, isChecked) => {
		if (!isChecked && selectedItems?.length == 0) {
			ordenesAsociadas?.filter(item => item?.selected == true)?.length == 1 && setBudgetType('');
		} else if (selectedItems?.length == 0) {
			setBudgetType(ordenAsociada?.paymentOrder?.details?.[0]?.presupuestoResponse?.budgetType);
		}
		setOrdenesAsociadas(ordenesAsociadas.map(item => {
			if (item.id == ordenAsociada.id) {
				item.selected = isChecked;
			}
			return item;
		}));
	}

	const openModalFigurative = fundRequestDetailDto => {
		setActiveFundRequestDetailDto(fundRequestDetailDto);
		setShowFigurativeModal(true);
	};

	const disableConditional = (item) => {
			return ((budgetType && budgetType !== item?.details?.[0]?.presupuestoResponse?.budgetType) ? true : false);
	};

	useEffect(() => {
		bankAccountId && clearFilters();
		return () => {
			setSelectedItems([]);
			dispatch(clearListOrderPayData());
		}
	}, [bankAccountId]);

	useEffect(() => {
		if (fundRequestEditSelected) {
			setOrdenesAsociadas(ordenesAsociadas.map(i => {
				i.selected = true;
				return i;
			}));
		}
	}, []);

	useEffect(() => {
		setFilteredSelectedItems(
			selectedItems.filter(selItems => !ordenesAsociadas.some(ordAs => ordAs.id === selItems.id))
		)
	}, [ordenesAsociadas, selectedItems]);

	useEffect(() => {
		if (fundRequestEditSelected?.details) {
			setOrdenesAsociadas(fundRequestEditSelected?.details ? fundRequestEditSelected?.details?.map( item => {
				item.selected = true;
				item.paymentOrder.asociated = true
				return item;
			}) : []);
	}
	}, [fundRequestEditSelected]);

	useEffect(() => {
		if (budgetType == undefined) {
			setBudgetType(fundRequestEditSelected?.details?.[0]?.paymentOrder?.details?.[0]?.presupuestoResponse?.budgetType)
		}
	}, [ordenes])


	return <>
		<h6 className='text-black-color my-3'>{`${PFLabelConstructor(selectedFundRequestType)?.title} disponibles`}</h6>
		{admServiceId && bankAccountId &&
			<>
				<Form onSubmit={stopPropagate(handleSubmit(onSubmitFormFilter))}>
					<Table striped bordered hover size='sm' >
						<thead>
							<tr className='secondary'>
								<th className='text-center align-middle text-white' width="15%">Expediente</th>
								<th className='text-center align-middle text-white' width="15%">{PFLabelConstructor(selectedFundRequestType)?.label}</th>
								<th className='text-center align-middle text-white' width="48%">Descripción</th>
								<th className='text-center align-middle text-white' width="12%">Importe</th>
								<th className='text-center align-middle text-white th-minwidth'>Acciones</th>
							</tr>
							<tr>
								<th>
									<div className='align-middle input-group mt-0'>
										<Form.Control placeholder="Organismo" className='text-center' size='sm' type='text' name="codeOrganismAdministrativeDocument" ref={regItem} />
										<Form.Control placeholder="N°" className='text-center' size='sm' type='text' name="numberAdministrativeDocument" ref={regItem} />
										<Form.Control placeholder="Año" className='text-center' size='sm' type='text' name="yearAdministrativeDocument" ref={regItem} />
									</div>
								</th>
								<th className='text-center align-middle'></th>
								<th className='text-center align-middle'>
									<Form.Control className='text-center' size='sm' type='text' name='description' placeholder='Ingresar una descripción para buscar' ref={regItem} />
								</th>
								<th className='text-center align-middle'></th>
								<th className='text-center align-middle'>
									<div className='d-flex justify-content-around'>
										<ActionIcon
											size="lg"
											id="search-button"
											className="btn-primary search-button text-white-color"
											toolTipText="Filtrar"
											icon={faFilter}
											type='submit'
										/>
										<ActionIcon
											size="lg"
											id="clean-filter"
											className="btn-primary clean-filter text-white-color"
											toolTipText="Limpiar filtros"
											icon={faBroom}
											onClick={() => clearFilters()}
										/>
									</div>
								</th>
							</tr>
						</thead>
						<tbody className='text-black-color'>
							{
								hasItemFetched ?
									<>
										{ordenes?.map((item, index) => (
											<tr key={item?.id}>
												<td className='text-center align-middle'>
													{item?.administrativeDocument?.codeOrganism} - {item?.administrativeDocument?.number}/{item?.administrativeDocument?.year}
												</td>
												<td className='text-center align-middle'>
													{item?.number}  -  {item?.year}
												</td>
												<td className='text-center align-middle'>
													{item?.description}
												</td>
												<td className='text-right align-middle'>
													{formatterPeso.format(SumCollectionAmountsOP(item?.details))}
												</td>
												<td className='text-center align-middle px-2 m-0 table-responsive btn-group'>
													<ActionIcon size='lg' 
														id={'details-orden' + index} 
														toolTipText='Detalle de la orden de pago' 
														icon={faSearch} 
														onClick={() => handleModalDetallePayOrder(item)} 
													/>
													{
														handleCheckCondition(item) ?
															<ActionIcon 
																className={`ml-2 ${disableConditional(item) && "not-allowed"}`}
																size='lg' 
																id={'add-orden' + index}
																toolTipText={(budgetType === item?.details?.[0].presupuestoResponse?.budgetType || !budgetType) 
																	? 'Seleccionar orden de pago'
																	: `Se debe seleccionar mismo tipo de partida: (${budgetType})`}
																icon={disableConditional(item) ? faBan : faPlus} 
																onClick={() => (budgetType === item?.details[0].presupuestoResponse?.budgetType || !budgetType) && addPayOrderToSelectionList(item)} 
															/>
															:
															<ActionIcon 
																className='text-danger ml-2' 
																size='lg' 
																id={'remove-orden-pago' + index} 
																toolTipText='Quitar de la selección' 
																icon={faTimes} 
																onClick={() => removePayOrderFromSelectionList(item)} 
															/>
													}
												</td>
											</tr>
										))
										}
									</>
									:
									<tr>
										<td colSpan='13' className='text-center'>
											{
												!hasItemFetched &&
												<Alert className='mb-0' variant='info' show={!isFetching}>
													<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
													No hay registros
												</Alert>
											}
										</td>
									</tr>
							}
						</tbody>
					</Table>
					<RCPagination
						activePage={page}
						itemsCountPerPage={defaultParams.size}
						totalItemsCount={totalItemsCount}
						pageRangeDisplayed={7}
						thePage={page}
						totalItems={totalItems}
						innerClass="justify-content-center"
						onChange={onPageChange}
					/>
				</Form>
			</>
		}

		{showFigurativeModal &&
			<FigurativeModal
				setShowFigurativeModal={setShowFigurativeModal}
				showFigurativeModal={showFigurativeModal}
				setSelectedItems={setSelectedItems}
				selectedItems={selectedItems}
				setActiveFundRequestDetailDto={setActiveFundRequestDetailDto}
				activeFundRequestDetailDto={activeFundRequestDetailDto}
				setOrdenesAsociadas={setOrdenesAsociadas}
				ordenesAsociadas={ordenesAsociadas}
			/>
		}

		<DetailModal
			showPayOrderDetailModal={showPayOrderDetailModal}
			setShowPayOrderDetailModal={setShowPayOrderDetailModal}
			activePayOrder={activePayOrder}
			selectedFundRequestType={selectedFundRequestType}
		/>
		<LiquidationDetailModal
			showPayOrderDetailModal={showLiquidationDetailModal}
			setShowPayOrderDetailModal={setShowLiquidationDetailModal}
			selectedOrderPay={activePayOrder}
		/>

		{(hasItemsSelected && viewSelectedItem) &&
			<>
				<Form.Group className='d-inline'>
					<Form.Label className='text-black-color pr-0 d-flex' >
						<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger' />
						<h6>{`${PFLabelConstructor(selectedFundRequestType)?.title} seleccionadas:`}</h6>
					</Form.Label>
				</Form.Group>
				<Table striped bordered hover size='sm'>
					<thead>
						<tr>
							<th className='text-center text-white' width="15%">Expediente</th>
							<th className='text-center text-white' width="15%">Orden de Pago</th>
							<th className='text-center text-white' width="30%">Descripción</th>
							<th className='text-center text-white' width="15%">Importe</th>
							<th className='text-center text-white' width="15%">Figurativa</th>
							<th className='text-center align-middle text-white th-minwidth'>Acciones</th>
						</tr>
					</thead>
					<tbody className='text-black-color'>
						{
							filteredSelectedItems
								.map((item, i) => {
									return (<tr key={item.id}>
										<td className='text-center align-middle'>
											{item?.paymentOrder?.administrativeDocument?.codeOrganism} - {item?.paymentOrder?.administrativeDocument?.number}/{item?.paymentOrder?.administrativeDocument?.year}
										</td>
										<td className='text-center align-middle'>
											{item?.paymentOrder?.number} - {item?.paymentOrder?.year}
										</td>
										<td className='text-center align-middle'>
											{item?.paymentOrder?.description}
										</td>
										<td className='text-right align-middle'>
											{formatterPeso.format(SumCollectionAmountsOP(item?.paymentOrder?.details))}
										</td>
										<td className='text-right align-middle'>
											{formatterPeso.format(figurativeCalculationTotalAmount(item?.paymentOrder?.details))}
										</td>
										<td className='text-center align-middle px-2 m-0 table-responsive btn-group'>
											{
												item?.paymentOrder?.hasFigurativeExpenditure &&
												<ActionIcon
													size='lg'
													id={'figurative-orden' + i}
													className='text-success'
													toolTipText={(item?.figurativeExpenditures) ? figurativeRegistrationCompleted : figurativeRegistrationMissing}
													icon={(item?.figurativeExpenditures) ? faClipboardCheck : faClipboardList}
													onClick={() => openModalFigurative(item?.paymentOrder)}
												/>
											}
											<ActionIcon size='lg' id={'details-orden' + i} toolTipText='Detalle de la orden de pago' icon={faSearch} onClick={() => handleModalDetallePayOrder(item?.paymentOrder)} />
											<ActionIcon size='lg' id={'remove-order' + i} toolTipText='Quitar de la selección' icon={faTimes} className='text-danger' onClick={() => removePayOrderFromSelectionList(item.paymentOrder)} />
										</td>
									</tr>
									)
								})
						}
					</tbody>
				</Table>
			</>
		}

		{!!ordenesAsociadas?.length &&
			<>
				<Form.Group className='d-inline'>
					<Form.Label className='text-black-color pr-0 d-flex' >
						<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger' />
						<h6>{`${PFLabelConstructor(selectedFundRequestType)?.title} asociadas:`}</h6>
					</Form.Label>
				</Form.Group>
				<Table striped bordered hover size='sm'>
					<thead>
						<tr>
							<th className='text-center text-white' width="15%">Expediente</th>
							<th className='text-center text-white' width="15%">Orden de Pago</th>
							<th className='text-center text-white' width="30%">Descripción</th>
							<th className='text-center text-white' width="15%">Importe</th>
							<th className='text-center text-white' width="15%">Figurativa</th>
							<th className='text-center text-white' width="5%"></th>
						</tr>
					</thead>
					<tbody className='text-black-color'>
						{
							ordenesAsociadas.map((item, i) => (
								<tr key={item.id}>
									<td className='text-center align-middle'>
										{item?.paymentOrder?.administrativeDocument?.codeOrganism} - {item?.paymentOrder?.administrativeDocument?.number}/{item?.paymentOrder?.administrativeDocument?.year}
									</td>
									<td className='text-center align-middle'>
										{item?.paymentOrder?.number} - {item?.paymentOrder?.year}
									</td>
									<td className='text-center align-middle'>
										{item?.paymentOrder?.description}
									</td>
									<td className='text-right align-middle'>
										{formatterPeso.format(SumCollectionAmountsOP(item?.paymentOrder?.details))}
									</td>
									<td className='text-right align-middle'>
										{formatterPeso.format(figurativeCalculationTotalAmount(item?.paymentOrder?.details))}
									</td>
									<td className='text-center align-middle px-2 m-0 table-responsive btn-group'>
										{(item?.paymentOrder?.hasFigurativeExpenditure && (budgetType == '' || budgetType == item?.paymentOrder?.details?.[0]?.presupuestoResponse?.budgetType)) &&
												<ActionIcon
													 
													id={'figurative-orden' + i}
													className='text-success mr-1'
													toolTipText={(item?.figurativeExpenditures) ? figurativeRegistrationCompleted : figurativeRegistrationMissing}
													icon={(item?.figurativeExpenditures) ? faClipboardCheck : faClipboardList}
													onClick={() => openModalFigurative(item?.paymentOrder)}
													disabled={!item?.selected}
												/>
											}
											{
												(budgetType != '' && budgetType != item?.paymentOrder?.details?.[0]?.presupuestoResponse?.budgetType)
													?
														<ActionIcon 
															className='not-allowed'
															size='lg' 
															toolTipText={`Se debe seleccionar mismo tipo de partida: (${budgetType})`}
															icon={faBan} 
														/>
													:
														<Form.Check
															className={`mx-2 mt-1 ext-black-color item${item?.paymentOrder?.id}`}
															checked={item?.selected}
															onChange={event => updateAsociadas(item, event.target.checked)}
														/>
											}
											{type == 'ORDEN_DE_PAGO_DE_HABERES' && <ActionIcon size='lg' className='mx-2' id={'liquidation-details-order' + i} toolTipText='Detalle de Liquidación' icon={faSearch} onClick={() => handleLiquidationDetailModal(item?.paymentOrder)} />}
									</td>
								</tr>
							))
						}
					</tbody>
				</Table>
			</>
		}

		{/* (pickedItems.length >= 5) &&
			<Alert className='mb-3' variant='warning' show={!isFetching}>
				<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
				No puede añadir mas de 5 Ordenes de Pago por Pedido de Fondos
			</Alert>
		*/}

		<Form.Control value={hasItemsSelected || hasItemsAsociados ? 'true' : ''} type='hidden' name='hasItemsSelected' ref={register(payOrderValidationObj)} />
		{!(hasItemsSelected || hasItemsAsociados) &&
			<FormFieldError errors={errors?.hasItemsSelected} />
		}

		{/* pickedItems.length>=6 &&
			<>
			<Form.Control value={null} type='hidden' name='isSelectedItem' ref={register(isSelectedItemValidationObj)} />
			<FormFieldError errors={errors?.isSelectedItem} />
			</>
		*/}

		<h6 className="text-right">Importe Pedido de Fondos {formatterPeso.format(calcTotalImportes(pickedItems, relatedAmounts))}</h6>
	</>

};

export default PayOrderListBox;