import React, { useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Col, Row, Card, Spinner, Alert  } from 'react-bootstrap';
import { DropdownList } from 'react-widgets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faChevronDown, faSearch, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import { organismCodeDropDownRequiredMsg } from 'src/utils/label';
import FormFieldError from 'src/components/general/FormFieldError';
import { useFormContext, useForm } from 'react-hook-form';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import TicketTable from 'src/components/pages/OrderPay/tables/TicketTable';
import ActionIcon from 'src/components/general/ActionIcon';
import { isNotEmptyArray } from 'src/services/validationService';
import { singleNumberFormatter,  onKeyDownEnter, getMsjAFIP  } from 'src/utils/utils';
import { getOrderTypeDate } from 'src/redux/orderPay/orderPayReducer';

import { tryGetPersonaNameByCuit } from 'src/redux/user/userActionCreator';
import { getCuitDataIsFetching } from 'src/redux/user/userReducer';

const ModalCertificadoServicios = props => {

	const dispatch = useDispatch();

    const { organismList, messagesDropDown, codeOrganism, handleOrganismOnChange, tipo, onlyRead } = props;
	
	const [ticket, setTicket] = useState("");
	const [amount, setAmount] = useState("");
	const [finalDate, setFinalDate] = useState("");
	const initialDate = useSelector(state => getOrderTypeDate(state));
	
	const receiptDate = 'Debe ingresar una fecha';
    const dateStartRequiredMsg = 'Debe ingresar una fecha de inicio';
    const dateEndRequiredMsg = 'Debe ingresar una fecha final';
	const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );
    const today = moment().format("YYYY-MM-DD");
    const minDate = moment(globalSelectedPeriod?.year.toString()).subtract(2, 'years').format("YYYY-MM-DD");

	const selectedOrganism = organismList?.find((item) => item.id == props?.data?.codeOrganism);

	//Para reutilizar modal en detalle de OP
	let register, errors, getValues;
	if(!props.data || !onlyRead){
		register = useFormContext().register;
		errors = useFormContext().errors;
		getValues = useFormContext().getValues;
	}else{
		register = useForm().register;
		errors = useForm().errors;
		getValues = useForm().getValues;
	}

	const ticketHandler = () => {
		props?.ticketObj?.setTicketData(prev => [...prev, {ticket, amount: singleNumberFormatter(amount)}])
		setTicket("");
		setAmount("");
		delete errors?.ticketAmount;
	}

	const organismDropDownObjValidation = { required: organismCodeDropDownRequiredMsg };
    const dateStartValidationObj = {
		required: dateStartRequiredMsg,
		validate: () => {
			const inicio = getValues()?.periodStart;
			const fin = getValues()?.periodEnd;
			if (inicio && fin && inicio > fin){
				return 'La fecha de inicio es superior a la fecha final';
			}else if ( inicio && (inicio < minDate || inicio > today) ){
				return 'Fecha inválida.';
			}
		}
    };
    const dateEndValidationObj = { 
		required: dateEndRequiredMsg,
		validate: () => {
			const inicio = getValues()?.periodStart;
			const fin = getValues()?.periodEnd;
			if (inicio && fin && inicio > fin){
				return 'La fecha de inicio es superior a la fecha final';
			}else if ( fin && (fin < minDate) ){
				return 'Fecha inválida.';
			}
		}
	};
	const receiptDateObjValidation = { 
		required: receiptDate,
		validate: () => {
			if (initialDate < finalDate) {
				return 'La fecha del recibo es superior a la fecha de la orden de pago';
			}
		}
	};
	const descriptionValidation = { required: 'Debe ingresar una descripción' };

	const onSelectDate = () => {
		setFinalDate(getValues()?.receiptDate);
		register(receiptDateObjValidation)
    };

	//CUIT
	const cuitDataIsFetching = useSelector(state => getCuitDataIsFetching(state));

	// Validations
    const cuitRequiredMsg = 'Debe ingresar un CUIT válido';
    const denominationRequiredMsg = 'Debe ingresar una denominación';

    // Form data
    const cuitValidationObj = { required: cuitRequiredMsg };
    const denominationValidationObj = { required: denominationRequiredMsg };

	const [removeState, setRemoveState] = useState(false);
    const [validarPersona, setValidarPersona] = useState(false);
	const [validatedPerson, setValidatedPerson] = useState(false);
    const [msjValidacionColor, setMsjValidacionColor] = useState('success');
    const [msjValidacion, setMsjValidacion] = useState('');
    const [personaFound, setPersonaFound] = useState(false);
    const [cuitValue, setCuitValue] = useState("");
    const [companyNameValue, setCompanyNameValue] = useState("");
	const [beneficiaryValidator, setBeneficiaryValidator] = useState('');
	const [providerNumber, setProviderNumber] = useState('');

	const clearBeneficiario = () => {
        setCuitValue("");
        setCompanyNameValue("");
        setRemoveState(false);
        setValidarPersona(false);
		setValidatedPerson(false);
        setPersonaFound(false);
		setBeneficiaryValidator('');
		setProviderNumber('');
    };

	const setAcreedorObject = (acreedor) => {
        const formattedCuit = acreedor?.cuit?.replace(/-/g, "");
        setCuitValue(formattedCuit);
        setCompanyNameValue(acreedor?.companyName || '');
        setPersonaFound(!!acreedor?.companyName);
        if (acreedor) setRemoveState(true);
    }

	const handleInputChange = (value) => {
        let cuit = value.replace(/-/g, "")
        let newCuit = cuit.replace(/X/g, "");
        setCuitValue(value);
        delete errors?.cuit;
        if (newCuit.length == 11 && !cuitDataIsFetching) {
            dispatch(tryGetPersonaNameByCuit(newCuit)).then((response) => {
				const responseData = JSON.parse(response?.data || '{}');
                const acreedor = {
                    cuit: newCuit,
                    id: responseData?.id,
                    companyName: responseData?.companyName,
                    beneficiaryValidator: responseData?.beneficiaryValidator,
                    providerNumber: responseData?.providerNumber
                };
                acreedor?.beneficiaryValidator ? setMsjValidacionColor('success') : setMsjValidacionColor('warning');
				acreedor?.beneficiaryValidator ? setValidatedPerson(true) : setValidatedPerson(false);
                acreedor?.companyName && delete errors?.companyName;
                acreedor?.id ? setValidarPersona(false) : setValidarPersona(true);
                setMsjValidacion(getMsjAFIP(acreedor?.beneficiaryValidator, acreedor?.providerNumber));
                setAcreedorObject(acreedor);
				setProviderNumber(acreedor?.providerNumber || '');
				setBeneficiaryValidator(acreedor?.beneficiaryValidator || '');
            });
        };
    };	

	const handleInputProveedor = (event) => {
        setCompanyNameValue(event.target.value);
    };

	useEffect(() => {
		if(props?.data?.cuit){
			setCuitValue(props?.data?.cuit);
			setCompanyNameValue(props?.data?.companyName);
			setProviderNumber(props?.data?.providerNumber || '');
			setBeneficiaryValidator(props?.data?.beneficiaryValidator || '');
			setRemoveState(true);
			if(props?.data?.beneficiaryValidator){
				setValidatedPerson(true);
				setMsjValidacionColor('success');
				setValidarPersona(false);
				setMsjValidacion(getMsjAFIP(props?.data?.beneficiaryValidator, props?.data?.providerNumber));
			} else {
				setValidatedPerson(false);
				setMsjValidacionColor('warning');
				setValidarPersona(true);
				setMsjValidacion(getMsjAFIP(props?.data?.beneficiaryValidator, props?.data?.providerNumber));
			}
		};
		return () => {
			!props?.data && handleOrganismOnChange();
			props?.ticketObj?.setTicketData([]);
		};
	}, [])

	return <>
	<Form.Group as={Row}>
        <Form.Label className='text-right d-flex mandatory-label' column sm='4'>
        <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
            Acreedor:
        </Form.Label>
        <Col sm='5'>
            <NumericFormat
                className="form-control"
                autoComplete="off"
                type='text'
                placeholder='CUIT'
				value={cuitValue}
                disabled={validarPersona || validatedPerson || cuitDataIsFetching || onlyRead}
                onChange={(event) => handleInputChange(event?.target?.value)}
                format="##-########-#"
                mask="X"
                pattern="\b(20|23|24|27|30|33|34)(\D)?[0-9]{8}(\D)?[0-9]"
                onKeyDown={event => onKeyDownEnter(event)}
            />
            <Form.Control hidden readOnly name='cuit' value={cuitValue} ref={register(cuitValidationObj)}/>
			<Form.Control hidden readOnly name='validatedCuit' value={validatedPerson} ref={register}/>
			<Form.Control hidden readOnly name='providerNumber' value={providerNumber} ref={register}/>
			<Form.Control hidden readOnly name='beneficiaryValidator' value={beneficiaryValidator} ref={register}/>
            <FormFieldError errors={errors?.cuit} />
        </Col>
	</Form.Group>

	<Form.Group as={Row}>
		<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
		<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
            Denominación:
        </Form.Label>
		<Col sm='5'>
                <Form.Control
                    autoComplete="off"
                    type='text'
                    readOnly={personaFound || validatedPerson || cuitDataIsFetching || onlyRead}
                    name='companyName'
					value={companyNameValue}
                    placeholder='Denominación'
                    onChange={handleInputProveedor}
                    ref={register(denominationValidationObj)}
                />
                <FormFieldError errors={errors?.companyName} />
            </Col>
        <Col sm={1} className='ml-0 pl-0 mt-2' >
            {
                cuitDataIsFetching ?
                <Spinner animation='border' size='sm' />
            :
                <ActionIcon size='lg' toolTipText='Quitar' icon={faTimes}
                    className={'text-danger ' + (removeState ? '' : 'd-none')}
                    onClick={clearBeneficiario}
                    text='Quitar'
                    hidden={onlyRead}
                    disabled={cuitDataIsFetching || onlyRead}
            	/>
            }
        </Col>
        <Col sm={5} className='offset-md-4' >
            {(validarPersona || validatedPerson) &&
                <Alert variant={msjValidacionColor} className='form-field-error mb-0 py-1 mt-2'>
                    {msjValidacion}
                </Alert>
            }
         </Col>
	</Form.Group>

	<Form.Group as={Row}>
		<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
			<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
			Organismo:
		</Form.Label>
        <Col sm='5'>
            <DropdownList filter
				dataKey='id'
                placeholder='Seleccione un organismo...'
                data={organismList}
                allowCreate='onFilter'
                textField='nameToShow'
                name='codeOrganism'
                as='select'
				disabled={onlyRead}
				defaultValue={props?.data && selectedOrganism?.id}
                onChange={ value => handleOrganismOnChange(value?.id)}
                messages={ messagesDropDown }
                selectIcon={<FontAwesomeIcon   icon={faChevronDown} className='text-black-color'/>}
                /*searchIcon={<FontAwesomeIcon   icon={faSearch} className='text-black-color'/>}*/ 
                >
            </DropdownList>
			<Form.Control hidden readOnly ref={register(organismDropDownObjValidation)} name='codeOrganism' value={codeOrganism} />
			<FormFieldError errors={errors?.codeOrganism} />
        </Col>
	</Form.Group>

	<Form.Group as={Row}>
		<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
			<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
			Fecha:
		</Form.Label>
		<Col sm='5'>
			<Form.Control
				type='date'
				disabled={onlyRead}
				name='receiptDate'
				defaultValue={props?.data?.receiptDate}
				ref={register(receiptDateObjValidation)}
				onChange={onSelectDate}
			/>
			<FormFieldError errors={errors?.receiptDate} />
		</Col>
	</Form.Group>

	<Form.Group as={ Row }>
		<Form.Label className='d-flex mandatory-label' column sm='4'>
			<FontAwesomeIcon icon={ faAsterisk } size='xs' className='mr-1 text-danger mandatory-asterisk' />
			Descripción:
		</Form.Label>
		<Col sm='5'>
			<Form.Control
				name='description'
				disabled={onlyRead}
				defaultValue={props?.data?.description}
				ref={register(descriptionValidation)}
			/>
			<FormFieldError errors={errors?.description} />
		</Col>
	</Form.Group>

	<Form.Group as={Row}>
        <Form.Label className='text-right d-flex mandatory-label' column sm='4'>
            <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
            Factura:
        </Form.Label>
        <Col sm='2'>
            <Form.Control
				name='ticket'
				disabled={onlyRead}
				defaultValue={props?.data?.ticket}
				onChange={(e) => setTicket(e.target.value)}
				value={ticket}
            />
        </Col>
		<Form.Label className='text-right d-flex mandatory-label' column sm='1'>
			<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
			Importe
		</Form.Label>
			<Col sm='2'>
				<NumericFormat
					className="form-control text-right"
					name='amount'
					disabled={onlyRead}
					inputMode="numeric"
					value={amount}
					placeholder='ej: $1.234,56'
					defaultValue={props?.data?.amount}
					onChange={(e) => setAmount(e.target.value)}
					thousandSeparator='.' decimalSeparator=','
					decimalScale='2'
				/>
			</Col>
			<ActionIcon className="ml-2" size='lg' toolTipText="Cargar Factura" disabled={!ticket || !amount} icon={faPlus} onClick={ticketHandler}/>
	</Form.Group>
		<Form.Group as={Row}>
			<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
				&nbsp;
			</Form.Label>
			<Col sm='5'>
				<FormFieldError errors={errors?.ticketAmount} />
				<Form.Control hidden readOnly ref={register({ required: "Debe ingresar una factura" })} name='ticketAmount' value={props?.ticketObj?.ticketData} />
			</Col>
		</Form.Group>
		{ 	(props?.data || isNotEmptyArray(props?.ticketObj?.ticketData)) &&
				<TicketTable
					ticketObj={props?.ticketObj}
					data={props?.data}
					onlyRead={onlyRead}
				/>
		}
	<Card variant='success' className='my-3'>
        <Card.Header>
             <h6 className='mb-0 text-center text-white'>
				Período
            </h6>
        </Card.Header>
	</Card>
	<Form.Group as={Row}>
		<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
			<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
			Inicio:
		</Form.Label>
		<Col sm='4'>
			<Form.Control
				type='date'
				name='periodStart'
				disabled={onlyRead}
				defaultValue={props?.data?.periodStart}
				ref={register(dateStartValidationObj)}
			/>
			<FormFieldError errors={errors?.periodStart} />
		</Col>
	</Form.Group>
	<Form.Group as={Row}>
		<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
			<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
			Final:
		</Form.Label>
		<Col sm='4'>
			<Form.Control
				type='date'
				name='periodEnd'
				disabled={onlyRead}
				defaultValue={props?.data?.periodEnd}
				ref={register(dateEndValidationObj)}
			/>
			<FormFieldError errors={errors?.periodEnd} />
		</Col>
	</Form.Group>
	<Form.Control hidden readOnly ref={register} name='type' value={tipo} />

</>};

export default ModalCertificadoServicios;