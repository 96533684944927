import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { config } from 'src/env.js';
import { Alert, Form, Table, Button, Spinner, FormControl, Row, Col, Card } from 'react-bootstrap';
import { faPlus, faExclamationTriangle, faTimes, faSearch, faFilter, faBroom } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import ActionIcon from 'src/components/general/ActionIcon';
import { isNotEmptyArray } from 'src/services/validationService';
import { getSubcodeListData, getSubcodeListIsFetching } from 'src/redux/subcode/subcodeReducer';
import { getNewAffectationSelectedCredits, getNewAffectationAdministrativeDocumentData, getAffectationSubcodeData } from 'src/redux/affectation/affectationReducer';
import { formatterPeso, getClearObject, getClearObjectAlt, numberNegativeRed, parseIntOrUndefined } from 'src/utils/utils';
import DetailModal from 'src/components/pages/CreditExecution/DetailModal';
import AppLoading from 'src/components/common/AppLoading';
import swal from 'sweetalert';
import moment from 'moment';
import { showError } from 'src/redux/globalData/globalDataActionCreator';
import { clearSubcodeListData } from 'src/redux/subcode/subcodeActions';
import { tryGetSubcodeList } from 'src/redux/subcode/subcodeActionCreator';
import { tryGetAffectationSubcode } from 'src/redux/affectation/affectationActionCreator';
import { getAffectationSubcodeisFetching } from 'src/redux/affectation/affectationReducer';
import { setNewAffectationDataSelectedCredits } from 'src/redux/affectation/affectationActions';
import { clearSelectedCredits } from 'src/redux/globalData/globalDataActions';
import { getGlobalDataSelectedPeriod, getGlobalDataPeriodsData } from 'src/redux/globalData/globalDataReducer';
import { serviceLabel, jurisdiction, characterLabel, account, purpose, functio, creditInitial, creditPartial, subcode, creditBalance, code, serviceAbbreviation, jurisdictionAbbreviation, unOrgAbbreviation, characterAbbreviation, purposeAbbreviation, functionAbbreviation, section, sectionAbbreviation, sector, sectorAbbreviation, creditInitialAbbreviation, creditPartialAbbreviation, codeAbbreviation, subcodeAbbreviation, organizationUnit, visibleName, visibleNameAbbrevation, actions, selectBudgetItem, emptyCredit, shortAccountAbbreviation} from 'src/utils/label';
import { dataPeriodsContructor, focusSelectInputByElementID } from 'src/utils/utils';
import { tryGetActiveAdminsitrativeServiceFromExercise } from 'src/redux/exercise/exerciseActionCreator';
import { getActiveAdministrativeServiceFromExerciseIsFetching } from 'src/redux/exercise/exerciseReducer';
import { setFigurativeExpedituresCompleteNumber, clearFigurativeExpedituresCompleteNumber, setFigurativeExpedituresVinculation, setFigurativeExpedituresAperturaDescription } from 'src/redux/figurativeExpeditures/figurativeExpedituresActions';
import { getFigurativeExpedituresVinculation} from 'src/redux/figurativeExpeditures/figurativeExpedituresReducer';
import RCPagination from 'src/components/common/RCPagination';

const BudgetItems = (props) => {
    const dispatch = useDispatch();
    const isFetching = useSelector(state => getSubcodeListIsFetching(state));
    const subcodeListingData = useSelector(state => getSubcodeListData(state));
    const selectedCredits = useSelector(state => getNewAffectationSelectedCredits(state));
    const affectationSubcodeData = useSelector(state => getAffectationSubcodeData(state));
    const periodSelected = useSelector(state => getGlobalDataSelectedPeriod(state));
    const periodsData = useSelector( state => getGlobalDataPeriodsData(state) )?.data?.sort( (a,b) => a.year<b.year ? 1 : -1 );
    const vinculations = useSelector(state => getFigurativeExpedituresVinculation(state));

    const searchSubcodeSelected = creditIdToSearch => selectedCredits?.find(item => (item.id == creditIdToSearch));
    const subcodeRecords = subcodeListingData?.data?.map(subcode => { subcode.selected = !searchSubcodeSelected(subcode.id);
		return subcode;
	});
    let hash = {};
    let subcodeRecordsList = subcodeRecords?.filter(o => hash[o.id] ? false : hash[o.id] = true);

    const CancelToken = axios.CancelToken;
    const { handleSubmit, register, reset, getValues } = useForm();
    let cancelSource = CancelToken.source();

    const [dataItem, setDataItem] = useState();
    const [dataModal, setDataModal] = useState();
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
        
    const administrativeDocument = useSelector(state => getNewAffectationAdministrativeDocumentData(state));
    const administrativeDocumentId = administrativeDocument?.id;
    const administrativeDocumentData = {...administrativeDocument, organizationCode: parseIntOrUndefined(administrativeDocument?.organizationCode)};

    const hasSubcodeRecords = isNotEmptyArray(subcodeRecords);
    const hasMoreSubcodes = subcodeListingData?.hasMore;
    const hasSelectedCredits = isNotEmptyArray(selectedCredits);

    const activeAdministrativeServiceFromExerciseIsFetching = useSelector(state => getActiveAdministrativeServiceFromExerciseIsFetching(state));
    const affectationSubcodeIsFetching = useSelector(state => getAffectationSubcodeisFetching(state));

    const fetch = props.isFetchingSubpartialBudgetOfSelectedCredits;

    const showExpedientCheck = props?.showExpedientCheck;
    const newExpedient = administrativeDocumentId != undefined ? false : true;
    const [ checkExpedient, setCheckExpedient] = useState((administrativeDocument?.id && isNotEmptyArray(administrativeDocument?.subCodes)) ? true : false);
    const expedientLabel = `Solo partidas del Expediente: ${administrativeDocument?.id ? administrativeDocument?.completeLabel : '-'}`;

    const aperturaType = props.apertura;
    const vinculationType = props.vinculation;
    const vinculationList = [];

    // Pagination
    const defaultParams = {
        size: 10,
        page: 1
    };

    const [filterObject, setFilterObject] = useState({});

    const page = subcodeListingData?.page;
    const perPage = subcodeListingData?.perPage
	const totalItemsCount = subcodeListingData?.totalCount ? subcodeListingData?.totalCount : 1;
    const totalItems = subcodeListingData?.totalCount ? subcodeListingData?.totalCount : '0';

    const onPageChange = (pNumber) => {

        const params = {
            ...defaultParams,
            filter: {...filterObject,  administrative_document_id: (showExpedientCheck && checkExpedient) ? administrativeDocumentId : undefined},
            page: pNumber
        };

        fetchList(params);
    };
    // End pagination

    const onChangeExpedientCheck = (value) => {
        setCheckExpedient(value);
        onSubmitForm(getValues());
    };

    const loadCredits = (params) => {
        reset();
		fetchList(params);
	};


    useEffect( () => {

        const params = {
            ...defaultParams,
            filter: {
                administrative_document_id: (showExpedientCheck && checkExpedient) ? administrativeDocumentId : undefined
            }
        };

        dispatch(clearSelectedCredits());
        dispatch(clearSubcodeListData());
        loadCredits(params)
    }, []);

    const onSubmitForm = (data) => {
        if(!isFetching) {

            const objToSend = (dataPeriodsContructor(periodSelected, periodsData).filter( (item) => item?.value === data?.tipoDeEjercicio));

			const objToSendFilter = objToSend.map(({visibleName, value, periodRpName, ...rest}) => ({...rest}))[0] || {};
			delete data["tipoDeEjercicio"];

			cancelSource.cancel();
			cancelSource = CancelToken.source();

            const cleanObjectToSend = getClearObjectAlt(objToSendFilter);

            let administrativeDocId = (showExpedientCheck && data?.administrative_document_id) ? administrativeDocumentId : administrativeDocId = undefined;
            delete data["administrative_document_id"];

            const filter = {
                period_rp_id: cleanObjectToSend?.period_rp_id,
                is_passive_remaining: cleanObjectToSend?.isPassiveRemaining,
                administrative_document_id: administrativeDocId
            };

            const filterToSend = getClearObjectAlt(filter);

			const finalData = getClearObject(data);

            const paramsToSend = {
                filter: {
                    ...filterToSend,
                    ...finalData
                }
            }
            setFilterObject({...filterToSend,...finalData});

			fetchList(paramsToSend);
        }
	};

    const fetchList = (params) => {
		dispatch(clearSubcodeListData());
		dispatch(tryGetSubcodeList(params));
	};
    const cleanFilters = () => {
		reset();
		setFilterObject({});
        setCheckExpedient(false);
		loadCredits(defaultParams);
	};
    const onMouseOverItem = item => {
		setDataItem(item);
	}

    const onClickValidationAddCredit = data => {        
        const figurativeExpedituresCompleteNumber = data?.completeNumber;
        const figurativeExpedituresAperturaDescription = data?.visibleName;
        dispatch(setFigurativeExpedituresAperturaDescription(figurativeExpedituresAperturaDescription))
        dispatch(setFigurativeExpedituresCompleteNumber(figurativeExpedituresCompleteNumber))

        let exerciseId = data?.code?.credit?.period?.id;
        let serviceId = data?.code?.credit?.service?.id;
        let serviceNumber = data?.code?.credit?.service?.number;
        let serviceName = data?.code?.credit?.service?.name;

        const params = {
			filter: {
				sub_code_id: data?.id,
				administrative_document_organism_code: administrativeDocumentData?.organizationCode,
				administrative_document_number: administrativeDocumentData?.number,
				administrative_document_year: administrativeDocumentData?.year
			}
		};

        dispatch(tryGetActiveAdminsitrativeServiceFromExercise(exerciseId, serviceId)).then( 
            response => {
                if (response?.status === 200 && !response?.data?.active) {

                    let dateCloseServiceData = response?.data?.dateClose;
                    let dateCloseService = (dateCloseServiceData ? moment(dateCloseServiceData).format('DD/MM/YYYY') : null);

                    swal({
                        title: 'Información',
                        text: `No se pueden realizar afectaciones al Servicio Administrativo: (${serviceNumber}) ${serviceName}, porque este servicio fue cerrado en fecha: ${dateCloseService}.`,
                        icon: 'info',
                        buttons: [false, "Aceptar"]
                    });
                }else{
                    dispatch(tryGetAffectationSubcode(params)).then(
                        response => {

                            const targetAffectationRpId = response?.data?.data[0]?.targetAffectationRpId;

                            if (response?.status === 200 && targetAffectationRpId == null) {
                                onClickAddCredit(data);
                            } else {
                                swal({
                                    title: 'Información',
                                    text: `No se puede seleccionar la partida. El expediente ${administrativeDocumentData?.completeLabel} para la partida se ha pasado a Residuo Pasivo`,
                                    icon: 'info',
                                    buttons: [false, "Aceptar"]
                                });
                            }
                        }
                    )
                }    
			}
        );
    }

    const onClickAddCredit = data => {
       
        const creditData = JSON.parse(JSON.stringify(data));
        const periodLabel = getValues()?.tipoDeEjercicio;
        const periodRpId = (dataPeriodsContructor(periodSelected, periodsData).filter( (item) => item?.value === periodLabel)[0]?.period_rp_id);

        const budgetItemType = {
            period_rp_id: periodRpId,
            label: periodLabel
        };

		if (!searchSubcodeSelected(creditData.id)) {
			const newSelectedCredits = (selectedCredits || []).concat({...creditData, budgetItemType});
			dispatch(setNewAffectationDataSelectedCredits(newSelectedCredits));
		}
		else {
			showError('Esa partida ya fue seleccionada.');
		}

        setTimeout(() => {
            focusSelectInputByElementID(`amount-${data?.id}`);
        }, [100])
	};

	const onClickRemoveCredit = creditData => {
		const newSelectedCredits = (selectedCredits || []).filter(item => item?.id != creditData?.id);
		dispatch(setNewAffectationDataSelectedCredits(newSelectedCredits));
        dispatch(clearFigurativeExpedituresCompleteNumber());
	};
    const onClickModalCredit = (creditData) => {
		const params = {
			filter: {
				sub_code_id: creditData?.id,
				administrative_document_organism_code: administrativeDocumentData?.organizationCode,
				administrative_document_number: administrativeDocumentData?.number,
				administrative_document_year: administrativeDocumentData?.year
			}
		};
		dispatch(tryGetAffectationSubcode(params)).then(
			response => {
				if (response?.status == 200 && isNotEmptyArray(response?.data?.data)) {
					creditData.balances = response?.data?.data[0]?.balance;
				}
				setDataModal(creditData);
				handleShow(true);
			}
		);
	};

    const onClickLoadMoreCredits = () => {
		dispatch(tryGetSubcodeList({ page: page + 1, filter: filterObject }));
	};

    const addVinculation = (data) => {
        const code = (data?.service_number ? data?.service_number : '*') 
                    + '-' + (data?.jurisdiction_number ? data?.jurisdiction_number : '*') 
                    + '-' + (data?.organization_number ? data?.organization_number : '*')
                    + '-' + (data?.character_number ? data?.character_number : '*')
                    + '-' + (data?.account_number ? data?.account_number : '*')
                    + '-' + (data?.purpose_number ? data?.purpose_number : '*')
                    + '-' + (data?.functionality_number ? data?.functionality_number : '*')
                    + '-' + (data?.section_number ? data?.section_number : '*' )
                    + '-' + (data?.sector_number ? data?.sector_number : '*')
                    + '-' + (data?.principal_budget_number ? data?.principal_budget_number : '*')
                    + '-' + (data?.partial_budget_number ? data?.partial_budget_number : '*')
                    + '-' + (data?.code_number ? data?.code_number : '*')
                    + '-' + (data?.sub_code_number ? data?.sub_code_number : '*');
        const name = data.visible_name ? data?.visible_name : ' ';
        const budgetFilters = {
            id: undefined,
            code: code, 
            name: name,
            shortName: undefined
        };
        dispatch(setFigurativeExpedituresVinculation(budgetFilters));
        cleanFilters();
    }

    return (
        <>
            <div className='d-flex align-items-center mb-3'>
                <p className='h6 text-black-color mt-0'>{selectBudgetItem}</p>
            </div>
            <Card.Title>
                <div className='text-black-color shadow-sm p-2 mb-5 bg-white rounded'>Referencia: {dataItem ? dataItem : '-'}</div>
            </Card.Title>
            <Form onSubmit={handleSubmit(onSubmitForm)} autoComplete='off'>
                <Col className='col-4 px-0 d-flex align-items-center justify-content-end'>
                    <Form.Control
                        as="select"
                        ref={register}
                        name='tipoDeEjercicio'
                        className="w-100 text-black-color"
                        onChange={()=>onSubmitForm(getValues())}
                    >
                        {dataPeriodsContructor(periodSelected, periodsData ).map( (item, i) =>
                            <option key={i} value={item?.value}>
                                {item.visibleName}
                              </option>
                          )}
                    </Form.Control>
                    <ActionIcon
                        className='ml-4'
                        size='lg'
                        toolTipText='Limpiar filtros '
                        icon={faBroom}
                        onClick={() => cleanFilters()}
                    />
                </Col>
                <br></br>
                {
                    showExpedientCheck &&
                        <Col className='px-0 mx-0'>
                            <Form.Check
						        id='expedient'
                                ref={register}
                                name='administrative_document_id'
                                className='text-black-color'
						        label={expedientLabel}
						        checked={checkExpedient}
						        onChange={ event => onChangeExpedientCheck(event.target.checked) }
                                disabled={newExpedient}
                            />
                        </Col>
                }
                <br></br>
                <Button className="d-none" type="submit">Send</Button>
                <Table striped hover size='sm' responsive >
                    <thead>
                        <tr>
                            <th className='text-center align-middle' title={serviceLabel}>{serviceAbbreviation}</th>
                            <th className='text-center align-middle' title={jurisdiction}>{jurisdictionAbbreviation}</th>
                            <th className='text-center align-middle' title={organizationUnit}>{unOrgAbbreviation}</th>
                            <th className='text-center align-middle' title={characterLabel}>{characterAbbreviation}</th>
                            <th className='text-center align-middle' title={account}>{shortAccountAbbreviation}</th>
                            <th className='text-center align-middle' title={purpose}>{purposeAbbreviation}</th>
                            <th className='text-center align-middle' title={functio}>{functionAbbreviation}</th>
                            <th className='text-center align-middle' title={section}>{sectionAbbreviation}</th>
                            <th className='text-center align-middle' title={sector}>{sectorAbbreviation}</th>
                            <th className='text-center align-middle' title={creditInitial}>{creditInitialAbbreviation}</th>
                            <th className='text-center align-middle' title={creditPartial}>{creditPartialAbbreviation}</th>
                            <th className='text-center align-middle' title={code}>{codeAbbreviation}</th>
                            <th className='text-center align-middle' title={subcode}>{subcodeAbbreviation}</th>
                            <th className='text-center align-middle th-minwidth' title={visibleName}>{visibleNameAbbrevation}</th>
                            <th className='text-center align-middle' width="10%">{creditBalance}</th>
                            <th className='text-center align-middle th-minwidth'>{actions}
                                <span className={activeAdministrativeServiceFromExerciseIsFetching ? '' : 'hidden'}>
                                    <Spinner className='spinner-border text-danger' animation='border' size='sm' />
                                </span>
                            </th>
                        </tr>
                        <tr className='secondary'>
                            <th>
                                <FormControl className='text-center' size='sm' type='number' name='service_number'  id='serviceid' ref={register} />
                            </th>
                            <th>
                                <FormControl className='text-center' size='sm' type='number' name='jurisdiction_number' ref={register} />
                            </th>
                            <th>
                                <FormControl className='text-center' size='sm' type='number' name='organization_number' ref={register} />
                            </th>
                            <th>
                                <FormControl className='text-center' size='sm' type='number' name='character_number' ref={register} />
                            </th>
                            <th>
                                <FormControl className='text-center' size='sm' type='number' name='account_number' ref={register} />
                            </th>
                            <th>
                                <FormControl className='text-center' size='sm' type='number' name='purpose_number' ref={register} />
                            </th>
                            <th>
                                <FormControl className='text-center' size='sm' type='number' name='functionality_number' ref={register} />
                            </th>
                            <th className='text-center align-middle'>
                                <FormControl className='text-center' size='sm' type='number' name='section_number' ref={register} />
                            </th>
                            <th className='text-center align-middle'>
                                <FormControl className='text-center' size='sm' type='number' name='sector_number' ref={register} />
                            </th>
                            <th>
                                <FormControl className='text-center' size='sm' type='number' name='principal_budget_number' ref={register} />
                            </th>
                            <th>
                                <FormControl className='text-center' size='sm' type='number' name='partial_budget_number' ref={register} />
                            </th>
                            <th>
                                <FormControl className='text-center' size='sm' type='number' name='code_number' ref={register} />
                            </th>
                            <th>
                                <FormControl className='text-center' size='sm' type='number' name='sub_code_number' ref={register} />
                            </th>
                            <th>
                                <FormControl className='text-center' size='sm' type='text' name='visible_name' ref={register} />
                            </th>
                            <th>
                                <Button className="d-none" type="submit">Send</Button>
                            </th>
                            <th className='text-center align-middle'>
                                <div className='d-flex justify-content-around'>
                                    <ActionIcon
                                        size="lg"
                                        id="search-button"
                                        name="search-button"
                                        className="btn-primary search-button text-white-color"
                                        toolTipText="Filtrar"
                                        icon={faFilter}
                                        type='submit'
                                        value="submit"
                                        onSubmit={onSubmitForm}	
                                    />
                                    <ActionIcon
                                        size="lg"
                                        id="clean-filter"
                                        name="clean-filter"
                                        className="btn-primary clean-filter text-white-color"
                                        toolTipText="Limpiar filtros"
                                        icon={faBroom}
                                        type='reset'
                                        onClick={()=> cleanFilters()}
                                    />
                                    {
                                        vinculationType &&
                                        <ActionIcon
                                            size="lg"
                                            id="add-vinculation"
                                            name="add-vinculation"
                                            className="btn-primary clean-filter text-white-color"
                                            toolTipText="Guardar"
                                            icon={faPlus}
                                            value="addVinculation"
                                            onClick={()=>addVinculation(getValues())}
                                        />
                                    }
                                </div>
                            </th>
                        </tr>
                    </thead>
                        <tbody className='text-black-color'>
                            {hasSubcodeRecords ?
                                subcodeRecordsList.map(item => {
                                    return (
                                        <tr key={item?.id}>
                                        <td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.code?.credit?.service?.name)} >
                                            {item?.code?.credit?.service?.number}
                                        </td>
                                        <td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.code?.credit?.organization.jurisdiction?.name)} >
                                            {item?.code?.credit?.organization?.jurisdiction?.number}
                                        </td>
                                        <td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.code?.credit?.organization?.name)} >
                                            {item?.code?.credit?.organization?.number}
                                        </td>
                                        <td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.code?.credit?.character?.name)} >
                                            {item?.code?.credit?.character?.code}
                                        </td>
                                        <td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.code?.credit?.account?.name)} >
                                            {item?.code?.credit?.account?.number}
                                        </td>
                                        <td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.code?.credit?.functionality.purpose?.name)} >
                                            {item?.code?.credit?.functionality?.purpose?.number}
                                        </td>
                                        <td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.code?.credit?.functionality?.name)} >
                                            {item?.code?.credit?.functionality?.number}
                                        </td>
                                        <td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.code?.credit?.partialBudget?.principalBudget?.sector?.section?.name)} >
                                            {item?.code?.credit?.partialBudget?.principalBudget?.sector?.section?.number}
                                        </td>
                                        <td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.code?.credit?.partialBudget?.principalBudget?.sector?.name)} >
                                            {item?.code?.credit?.partialBudget?.principalBudget?.sector?.number}
                                        </td>
                                        <td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.code?.credit?.partialBudget.principalBudget?.name)} >
                                            {item?.code?.credit?.partialBudget?.principalBudget?.number}
                                        </td>
                                        <td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.code?.credit?.partialBudget?.name)} >
                                            {item?.code?.credit?.partialBudget?.number}
                                        </td>
                                        <td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.code?.name)} >
                                            {item?.code?.number}
                                        </td>
                                        <td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.name)} >
                                            {item?.number}
                                        </td>
                                        <td className='text-center align-middle' onMouseOver={() => onMouseOverItem()} >
                                            {item?.visibleName}
                                        </td>
                                        <td className={`${numberNegativeRed(item?.balance?.currentBalance)} text-right align-middle`}>
                                            {formatterPeso.format(item?.balance?.currentBalance)}
                                        </td>

                                        <td className='text-center align-middle' onMouseOver={() => onMouseOverItem()}>
                                            {
                                                !vinculationType &&
                                                <>
                                                    <ActionIcon size='lg' id='details-credit' toolTipText='Detalle de la partida' icon={faSearch} onClick={() => onClickModalCredit(item, affectationSubcodeData)} />
                                                    {item?.selected ?
                                                        <ActionIcon size='lg' id='add-credit' toolTipText='Seleccionar partida presupuestaria' disabled={activeAdministrativeServiceFromExerciseIsFetching || affectationSubcodeIsFetching} icon={faPlus} onClick={() => onClickValidationAddCredit(item)} />
                                                        :
                                                        <ActionIcon size='lg' id='remove-credit-2' toolTipText='Quitar de la selección' icon={faTimes} className='text-danger' onClick={() => {onClickRemoveCredit(item)}} />
                                                    }
                                                </>
                                            }
                                        </td>
                                    </tr>
                                    );
                                })
                                :
                                <tr>
                                    <td colSpan='16' className='text-center'>
                                        {!isFetching &&
                                            <Alert variant='info' className='mb-0'>
                                                <FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
                                                {emptyCredit}
                                            </Alert>
                                        }
                                    </td>
                                </tr>
                            }
                        </tbody>
                </Table>
                {hasSubcodeRecords &&
							<RCPagination
				    			activePage={page}
								itemsCountPerPage={perPage}
								totalItemsCount={totalItemsCount}
								pageRangeDisplayed={7}
								thePage={page}
								totalItems={totalItems}
								innerClass="justify-content-center"
								onChange={onPageChange}
							/> 
						}  
            </Form>	
            
            <DetailModal
                show = {show}
                handleClose = {handleClose}
                dataModal = { dataModal }
                />
            <AppLoading isLoading={isFetching || fetch} />
        </> 
    )};

export default BudgetItems;
