import * as ACTIONS from './preloadActions';
import * as SERVICES from 'src/services/preloadServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';

//Preload getter
export const tryGetPreloadData = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getPreloadDataRequest());

		if (accessToken) {

			const response = await SERVICES.getPreloadData(accessToken, params);

			if (response?.status === 200) {
				dispatch(ACTIONS.getPreloadDataRequestSuccess(response?.data));
				return response?.data;
			}
			else {
				dispatch( ACTIONS.getPreloadDataRequestFailure(response?.error||response) );
				showError(`Error al cargar las precargas. Detalle: ${response?.error||response}` );
			}
		}

	};
};

//Delete Preload
export const tryDeletePreload = (id) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.deletePreloadRequest());

		if (accessToken) {

			const response = await SERVICES.deletePreload(accessToken, id);

			if (response?.status == 200) {
				dispatch(ACTIONS.deletePreloadRequestSuccess(response?.data));
				showSuccess('Precarga eliminada correctamente.');
			}
			else {
				dispatch(ACTIONS.deletePreloadRequestFailure(response?.error || response));
				showError(`Error al eliminar la precarga. Detalle: ${response?.data?.message || response}`);
			}
			return response;
		}

	};
};

//Edit preload
export const tryEditPreload = preloadData => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.editPreloadRequest());

		if (accessToken) {

			const response = await SERVICES.editPreload(accessToken, preloadData);

			if (response?.status == 200) {
				dispatch(ACTIONS.editPreloadRequestSuccess(response?.data));
				showSuccess('Precarga editada correctamente.');
			}
			else {
				dispatch(ACTIONS.editPreloadRequestFailure(response?.error || response));
				showError(`Error al editar la precarga. Detalle: ${response?.response?.data?.message || response?.error}`);
			}
			return response;
		}

	};

};

//Attach legal instrument to preload
export const tryAttachLegalInstrumentToPreload = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.getAttachLegalInstrumentToPreloadRequest());

		if (accessToken) {

			const response = await SERVICES.attachLegalInstrumentToPreload(accessToken, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.getAttachLegalInstrumentToPreloadRequestSuccess(response?.data));
				showSuccess('Instrumento legal asociado correctamente.');
			}
			else {
				dispatch(ACTIONS.getAttachLegalInstrumentToPreloadRequestFailure(response?.data?.error));
				showError(`Error al agregar instrumento legal. Detalle: ${response?.data?.message}`);
			}
			return response
		}

	};
};

export const tryGetFilePreload = (paramsToSend) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		if (accessToken) {
			dispatch(ACTIONS.getFileSubcodeRequest());
			const response = await SERVICES.getFilePreloadServ(accessToken, paramsToSend);

			if (response?.status == 200) {
				dispatch(ACTIONS.getFilePreloadRequestSuccess(response?.data));
				showSuccess('Exportación generada con éxito');
			}
			else {
				dispatch(ACTIONS.getFilePreloadRequestFailure(response?.response?.data || response));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}
	};
};

export const tryGetPreloadReport = (paramsToSend) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		if (accessToken) {
			dispatch(ACTIONS.getPreloadReportRequest());
			const response = await SERVICES.getPreloadReport(accessToken, paramsToSend);

			if (response?.status == 200) {
				dispatch(ACTIONS.getPreloadReportRequestSuccess(response?.data));
				showSuccess(dispatch, 'El reporte se descargó correctamente.');
			}
			else {
				dispatch(ACTIONS.getPreloadReportRequestFailure(response?.response?.data || response));
				showError(dispatch, `Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		};
	};
};

// Llama al servicio de descarga de PDF de precarga por expediente, pasando token y params
export const tryGetPreloadPDF = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		if (accessToken) {
			SERVICES.getFilePreloadPDF(accessToken, params);
		}
	};
};