import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Form, Spinner, Row, Col, Button } from 'react-bootstrap';
import { useForm, useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';

import PageTitle from 'src/components/general/PageTitle';
import { required, cancel, save, editCaption, caption} from 'src/utils/label';

import { CAPTION_LIST } from 'src/utils/constants';
import { tryEditCaption } from 'src/redux/caption/captionActionCreator';
import { getCaptionEditIsFetching, getCaptionToEdit } from 'src/redux/caption/captionReducer';
import CaptionForm  from 'src/components/forms/caption/captionForm';

const CaptionEditPage = props => {

	const dispatch = useDispatch();

	const hookFormMethods = useForm();
    const { handleSubmit } = hookFormMethods;

    const captionEditIsFetching = useSelector(state => getCaptionEditIsFetching(state));

	// Caption to edit data
	const captionToEdit = useSelector(state => getCaptionToEdit(state));
	Object.assign(hookFormMethods, {captionToEdit});

	const onFormSubmit = data => {
		const year = data?.year;
		const caption = data?.caption;

		const params = {
			caption: caption,
			year: year
		}

		dispatch(tryEditCaption(params));
	};

	return <Container fluid className='mb-5'>
		<Card>

			<PageTitle text={caption} />

			<Container fluid>
				<Card className='mb-3'>

					<Card.Header>
						<h6 className='mb-0'>{editCaption}</h6>
					</Card.Header>

					<Card.Body >

						<Row>
							<Col xs={8}>
								<h6 className='mb-4 text-black-color'>
								</h6>
							</Col>

							<Col xs={4} className='text-right font-weight-bold font-italic'>
								<span className='text-danger d-flex mandatory-label'>
									<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
									{required}
								</span>
							</Col>
						</Row>

						<useFormContext {...hookFormMethods} >
							<Form onSubmit={handleSubmit(onFormSubmit)} className='text-black-color mt-5 mb-5'>
								<CaptionForm />

								<div class='d-flex justify-content-around mt-4 mb-3'>
									<Button 
										variant='danger' 
										size='lg'
										disabled={captionEditIsFetching}
										onClick={() => dispatch(push(CAPTION_LIST))}
									>
										{cancel}
									</Button>

									<span className={(captionEditIsFetching ? '' : 'hidden')}>
										<Spinner className='spinner-border text-danger' animation='border' />
									</span>

									<Button 
										type='submit' 
										variant='success' 
										size='lg'
										disabled={captionEditIsFetching}
									>
										{save}
									</Button>
								</div>
							</Form>
						</useFormContext>
						
					</Card.Body>
				</Card>
			</Container>
		</Card>
	</Container>;
};

export default CaptionEditPage;