import * as ACTIONS from './provisionOrderActions';
import * as SERVICES from 'src/services/provisionOrderServices';
import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';

// GET orden de provision
export const tryGetProvisionOrderDetails = (params) => {
	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );
		dispatch( ACTIONS.getProvisionOrderListRequest() );
		if(accessToken){
			const response = await SERVICES.getProvisionOrder( accessToken, params );
			if( response?.status === 200 ) {
				dispatch( ACTIONS.getProvisionOrderListRequestSuccess(response?.data) );
				return response?.data;
			} else {
				dispatch( ACTIONS.getProvisionOrderListRequestFailure(response?.error||response) );
				showError(`Error al cargar los detalles de orden de provisión. Detalle: ${response?.error||response}` );
			}
		};
	};
};

// EDIT
export const tryPutProvisionOrder = ( params, id) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.getEditProvisionOrderRequest());
		if (accessToken) {
			const response = await SERVICES.editProvisionOrder(accessToken, id, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.getEditProvisionOrderRequestSuccess(response?.data));
				showSuccess('Orden de provision editado correctamente.');
			}
			else {
				dispatch(ACTIONS.getEditProvisionOrderRequestFailure(response?.data?.error));
				showError(`Error al editar el orden de provision. Detalle: ${response?.data?.message}`);
			}
			return response
		};
	};
};

// Try Set  Fund Requests
export const tryProvisionOrderById = (id) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getProvisionOrderByIdRequest());

		if (accessToken) {

			const response = await SERVICES.getProvisionOrderId(accessToken, id);
			
			if (response?.status === 200) {
				dispatch(ACTIONS.getProvisionOrderByIdRequestSuccess(response?.data));
				return response?.data;
			}
			else {
				dispatch(ACTIONS.getProvisionOrderByIdRequestFailure(response?.error || response));
				showError(`Error al cargar datos. Detalle: ${response?.error || response}`);
			}
		}

	};
};

// POST orden de provision
export const tryPostProvisionOrder = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.postProvisionOrderRequest());
		if (accessToken) {
			const response = await SERVICES.postProvisionOrder(accessToken, params);
			if (response?.status> 199 && response?.status < 300){
				dispatch( ACTIONS.getProvisionOrderListRequestSuccess(response?.data) );
				showSuccess('Orden de provision registrado correctamente.');
			} else {
				dispatch( ACTIONS.getProvisionOrderListRequestFailure(response?.data?.error) );
				showError(`Error al crear la orden de provision. Detalle: ${response?.data?.message}`);
			}
			return response
		}
	}
}

// DELETE orden de provision
export const tryDeleteProvisionOrder = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.deleteProvisionOrderRequest());
		if (accessToken) {
			const response = await SERVICES.deleteProvisionOrder(accessToken, params);
			if (response?.status == 204) {
				dispatch(ACTIONS.deleteProvisionOrderRequestSuccess(response?.data));
				showSuccess('Orden de provisión eliminada correctamente.');
			} else {
				dispatch(ACTIONS.deleteProvisionOrderRequestFailure(response?.data?.error));
				showError(`Error al eliminar la Orden de provisión. Detalle: ${response?.data?.message}`);
			}
			return response;
		}
	};
};

// GET Adquisition types list
export const tryAcquisitionTypeList = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.getAcquisitionTypesListRequest());
		if (accessToken) {
			const response = await SERVICES.getAcquisitionTypesList(accessToken);
			if (response?.status == 200) {
				dispatch(ACTIONS.getAcquisitionTypesListRequestSuccess(response?.data));
			} else {
				dispatch(ACTIONS.getAcquisitionTypesListRequestFailure(response?.data?.error));
				showError(`Error al obtener los tipos de contratación. Detalle: ${response?.data?.message}`);
			}
			return response;
		}
	};
};