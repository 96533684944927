import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext, useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Col, Row, Card, Spinner, Alert } from 'react-bootstrap';
import { faAsterisk, faChevronDown, faSearch, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import { getListAllServiceAdministrativeByUserData } from "src/redux/administrativeService/administrativeServiceReducer";
import { DropdownList } from 'react-widgets';
import FormFieldError from 'src/components/general/FormFieldError';
import { getAdministrativeDocumentYearsOptions, parseIntOrUndefined, singleNumberFormatter, onKeyDownEnter, getMsjAFIP } from 'src/utils/utils';
import {
	serviceRequiredMsg, organismCodeDropDownRequiredMsg, descriptionRequiredMsg, yearRequiredMsg,
	numberRequiredOrderPayMsg, dateRequiredOrderPayMsg, penaltyFeeRequiredOrderPayMsg
} from 'src/utils/label.js'
import ActionIcon from 'src/components/general/ActionIcon';
import { isNotEmptyArray } from 'src/services/validationService';
import TicketTable from 'src/components/pages/OrderPay/tables/TicketTable';
import { getOrderTypeDate } from 'src/redux/orderPay/orderPayReducer';

import { tryGetPersonaNameByCuit } from 'src/redux/user/userActionCreator';
import { getCuitDataIsFetching } from 'src/redux/user/userReducer';

import { tryGetProvisionOrderDetails } from 'src/redux/provisionOrder/provisionOrderActionCreator';
import { getProvisionOrderListIsFetching } from 'src/redux/provisionOrder/provisionOrderReducer';

const ModalReceptionAct = props => {

	const dispatch = useDispatch();

	const receiptDate = 'Debe ingresar una fecha';

	const { organismList, messagesDropDown,
		handleModalAdmServiceIdOnChange, codeOrganism, handleOrganismOnChange, tipo, onlyRead, admCode, admNumber, admYear, data } = props;

	const [ticket, setTicket] = useState("");
	const [amount, setAmount] = useState("");
	const [tempAdmServiceId, setTempAdmServiceId] = useState(data?.provisionOrder?.administrativeServiceId || '');
	const initialDate = useSelector(state => getOrderTypeDate(state));
	const [finalDate, setFinalDate] = useState("");
	const [provisionOrderNumber, setProvisionOrderNumber] = useState(props?.data?.provisionOrder?.number || '');
	const [provisionOrderYear, setProvisionOrderYear] = useState(props?.data?.provisionOrder?.year || '');
	const [provisionOrderId, setProvisionOrderId] = useState(parseIntOrUndefined(props?.data?.provisionOrder?.id) || '');
	const [initiator, setInitiator] = useState(props?.data?.provisionOrder?.initiator || '');

	const provisionOrderListIsFetching = useSelector(state => getProvisionOrderListIsFetching(state));

	const handlerProvionOrderNumber = (value) => {
		setProvisionOrderNumber(value);
		delete errors?.orderProvisionNumber;
	};

	const handlerProvionOrderYear = (value) => {
		setProvisionOrderYear(value ? parseIntOrUndefined(value) : '');
		delete errors?.orderProvisionYear;
	};

	const [penaltyFeeValue, setPenaltyFeeValue] = useState((props?.data?.penaltyFee || 0)?.toString()?.replaceAll('.', ','));
	const maxValue = 99999999999;

	const withValueLimit = (inputObj) => {
		const { value } = inputObj;
		if (value <= maxValue)
			return inputObj;
	};

	//Para reutilizar modal en detalle de OP
	let register, errors, codeAdministrative, getValues;
	if (!props.data || !onlyRead) {
		register = useFormContext().register;
		errors = useFormContext().errors;
		codeAdministrative = useFormContext().codeAdministrative;
		getValues = useFormContext().getValues;
	} else {
		register = useForm().register;
		errors = useForm().errors;
		codeAdministrative = useForm().codeAdministrative;
		getValues = useForm().getValues;
	}

	const yearsOptions = getAdministrativeDocumentYearsOptions();
	const organismDropDownObjValidation = { required: organismCodeDropDownRequiredMsg };
	const serviceValidationObj = { required: serviceRequiredMsg };
	const descriptionValidation = { required: descriptionRequiredMsg };

	const yearValidation = { required: yearRequiredMsg };
	const numberValidation = { required: numberRequiredOrderPayMsg };
	const provisionOrderNumberValidation = { required: numberRequiredOrderPayMsg };
	const provisionOrderYearValidation = { required: yearRequiredMsg };
	const penaltyFeeValidation = { required: penaltyFeeRequiredOrderPayMsg };
	const receiptDateObjValidation = {
		required: receiptDate,
		validate: () => {
			if (initialDate < finalDate) {
				return 'La fecha del recibo es superior a la fecha de la orden de pago';
			}
		}
	};
	
	//CUIT
	const cuitDataIsFetching = useSelector(state => getCuitDataIsFetching(state));

	// Validations
	const cuitRequiredMsg = 'Debe ingresar un CUIT válido';
	const denominationRequiredMsg = 'Debe ingresar una denominación';
	const provisionOrderRequireMsg = 'Debe seleccionar una Orden de Provisión.';
	const provisionOrderByCuitValidationObj = { required: provisionOrderRequireMsg };

	// Form data
	const cuitValidationObj = { required: cuitRequiredMsg };
	const denominationValidationObj = { required: denominationRequiredMsg };

	const [removeState, setRemoveState] = useState(false);
	const [validarPersona, setValidarPersona] = useState(false);
	const [validatedPerson, setValidatedPerson] = useState(false);
	const [msjValidacionColor, setMsjValidacionColor] = useState('success');
	const [msjValidacion, setMsjValidacion] = useState('');
	const [personaFound, setPersonaFound] = useState(false);
	const [cuitValue, setCuitValue] = useState("");
	const [companyNameValue, setCompanyNameValue] = useState("");
	const [beneficiaryValidator, setBeneficiaryValidator] = useState('');
	const [providerNumber, setProviderNumber] = useState('');
	const [associatedProvisionOrders, setAssociatedProvisionOrders] = useState([]);
	const hasAssociatedProvisionOrders = isNotEmptyArray(associatedProvisionOrders);

	
	const disableByProvisionOrder = isNotEmptyArray(associatedProvisionOrders) || provisionOrderListIsFetching;

	const clearBeneficiario = () => {
		setCuitValue("");
		setCompanyNameValue("");
		setRemoveState(false);
		setValidarPersona(false);
		setValidatedPerson(false);
		setPersonaFound(false);
		setBeneficiaryValidator('');
		setProviderNumber('');
		setAssociatedProvisionOrders([]);
	};

	const setAcreedorObject = (acreedor) => {
		const formattedCuit = acreedor?.cuit?.replace(/-/g, "");
		setCuitValue(formattedCuit);
		setCompanyNameValue(acreedor?.companyName || '');
		setPersonaFound(!!acreedor?.companyName);
		if (acreedor) setRemoveState(true);
	}

	const handleInputChange = (value) => {
		let cuit = value?.toString()?.replace(/-/g, "")
		let newCuit = cuit.replace(/X/g, "");
		setCuitValue(value);
		delete errors?.cuit;
		if (newCuit.length == 11 && !cuitDataIsFetching) {
			dispatch(tryGetPersonaNameByCuit(newCuit)).then((response) => {
				const responseData = JSON.parse(response?.data || '{}');
				const acreedor = {
					cuit: newCuit,
					id: responseData?.id,
					companyName: responseData?.companyName,
					beneficiaryValidator: responseData?.beneficiaryValidator,
					providerNumber: responseData?.providerNumber
				};
				acreedor?.beneficiaryValidator ? setMsjValidacionColor('success') : setMsjValidacionColor('warning');
				acreedor?.beneficiaryValidator ? setValidatedPerson(true) : setValidatedPerson(false);
				acreedor?.companyName && delete errors?.companyName;
				acreedor?.id ? setValidarPersona(false) : setValidarPersona(true);
				setMsjValidacion(getMsjAFIP(acreedor?.beneficiaryValidator, acreedor?.providerNumber));
				setAcreedorObject(acreedor);
				setProviderNumber(acreedor?.providerNumber || '');
				setBeneficiaryValidator(acreedor?.beneficiaryValidator || '');
			});
		};
	};

	const handleInputProveedor = (event) => {
		setCompanyNameValue(event.target.value);
	};

	const administrativeServiceData = useSelector(state => getListAllServiceAdministrativeByUserData(state))
		?.sort((a, b) => a.code > b.code ? 1 : -1)
		?.map(item => ({
			displayName: `${item?.code} - ${item?.name} `,
			id: item?.id,
			name: item?.name
		}));

	const preselectedAdmService = administrativeServiceData?.find((item) => item.id == props?.data?.provisionOrder?.administrativeServiceId) || administrativeServiceData?.find(i => i.id == codeAdministrative);

	const selectedOrganism = organismList?.find((item) => item.id == props?.data?.codeOrganism);

	const handleActReceptionService = (value) => {
		handleModalAdmServiceIdOnChange(value);
		setTempAdmServiceId(value);
	};

	const handleActReceptionProvisionOrderByCuit = (value) => {
		setProvisionOrderId(value?.id || '');
		handleActReceptionService(value?.administrativeServiceId);
		handlerProvionOrderNumber(value?.number);
		handlerProvionOrderYear(value?.year);
		setInitiator(value?.initiator);
		handleInputChange(value?.beneficiary?.persona?.cuit);
		delete errors?.provisionOrderId;
	};

	const ticketHandler = () => {
		props?.ticketObj?.setTicketData(prev => [...prev, { ticket, amount: singleNumberFormatter(amount) }])
		setTicket("");
		setAmount("");
		delete errors?.ticketAmount;
	};

	const onSelectDate = () => {
		setFinalDate(getValues()?.receiptDate);
		register(receiptDateObjValidation)
	};

	const [showProvisionOrderCard, setShowProvisionOrderCard] = useState(false);

	useEffect(() => {
		if (props?.data?.cuit) {
			setCuitValue(props?.data?.cuit);
			setCompanyNameValue(props?.data?.companyName || '');
			setProviderNumber(props?.data?.providerNumber || '');
			setBeneficiaryValidator(props?.data?.beneficiaryValidator || '');
			setRemoveState(true);
			if (props?.data?.beneficiaryValidator) {
				setValidatedPerson(true);
				setMsjValidacionColor('success');
				setValidarPersona(false);
				setMsjValidacion(getMsjAFIP(props?.data?.beneficiaryValidator, props?.data?.providerNumber));
			} else {
				setValidatedPerson(false);
				setMsjValidacionColor('warning');
				setValidarPersona(true);
				setMsjValidacion(getMsjAFIP(props?.data?.beneficiaryValidator, props?.data?.providerNumber));
			}
		};
		return () => {
			!props?.data && handleOrganismOnChange();
			!props?.data && handleModalAdmServiceIdOnChange();
			props?.ticketObj?.setTicketData([]);
		};
	}, []);

	useEffect(() => {
		if(admCode && admNumber && admYear && (!props?.data?.provisionOrder?.number || (props?.data?.provisionOrder?.number && props?.data?.provisionOrder?.initiator) )){
			const paramsToSend = {
				'ad.codeOrganism': admCode,
				'ad.number': admNumber,
				'ad.year': admYear,
				'administrativeServiceId': codeAdministrative
			};
			dispatch(tryGetProvisionOrderDetails(paramsToSend)).then(response => {
				setAssociatedProvisionOrders(response?.records?.map((item) => ({
					id: item?.id,
					number: item?.number,
					administrativeServiceId: item?.administrativeServiceId,
					year: item?.year,
					initiator: item?.initiator,
					name: item?.number + ' - ' + item?.initiator + ' - ' + administrativeServiceData?.filter(service => service?.id ==  item?.administrativeServiceId)?.[0]?.name,
					beneficiary: item?.beneficiary
				})) || []);
			});
		};

		if (provisionOrderNumber !== null && provisionOrderYear !== null && provisionOrderNumber !== undefined && provisionOrderYear !== undefined && provisionOrderNumber !== '' && provisionOrderYear !== '') {
			setShowProvisionOrderCard(true);
		}
	}, []);

	return <>
		<Card variant='success' className='my-3'>
			<Card.Body>
				<Form.Group as={Row}>
					<Form.Label htmlFor='asunto' className='text-right text-black-color pr-0 d-flex mandatory-label col-sm-3'>
						<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
						Acreedor:
					</Form.Label>
					<Col sm='4' className='paddingform pr-1'>
						<NumericFormat
							className="form-control"
							autoComplete="off"
							type='text'
							placeholder='CUIT'
							value={cuitValue}
							disabled={validarPersona || validatedPerson || cuitDataIsFetching || onlyRead || (disableByProvisionOrder && provisionOrderId)}
							onChange={(event) => handleInputChange(event?.target?.value)}
							format="##-########-#"
							mask="X"
							pattern="\b(20|23|24|27|30|33|34)(\D)?[0-9]{8}(\D)?[0-9]"
							onKeyDown={event => onKeyDownEnter(event)}
						/>
						<Form.Control hidden readOnly name='cuit' value={cuitValue} ref={register(cuitValidationObj)} />
						<Form.Control hidden readOnly name='validatedCuit' value={validatedPerson} ref={register} />
						<Form.Control hidden readOnly name='providerNumber' value={providerNumber} ref={register} />
						<Form.Control hidden readOnly name='beneficiaryValidator' value={beneficiaryValidator} ref={register} />
						<FormFieldError errors={errors?.cuit} />
					</Col>
					<Col sm='4' className='pl-1' >
						<Form.Control
							autoComplete="off"
							type='text'
							readOnly={personaFound || validatedPerson || cuitDataIsFetching || onlyRead || (disableByProvisionOrder && provisionOrderId)}
							name='companyName'
							value={companyNameValue}
							placeholder='Denominación'
							onChange={handleInputProveedor}
							ref={register(denominationValidationObj)}
						/>
						<FormFieldError errors={errors?.companyName} />
					</Col>
					<Col sm={1} className='ml-0 pl-0 mt-2' >
						{
							cuitDataIsFetching ?
								<Spinner animation='border' size='sm' />
								:
								<ActionIcon size='lg' toolTipText='Quitar' icon={faTimes}
									className={'text-danger ' + (removeState ? '' : 'd-none')}
									onClick={clearBeneficiario}
									text='Quitar'
									hidden={onlyRead || (disableByProvisionOrder && provisionOrderId)}
									disabled={cuitDataIsFetching || onlyRead}
								/>
						}
					</Col>
					<Col sm={8} className='offset-md-3' >
						{(validarPersona || validatedPerson) &&
							<Alert variant={msjValidacionColor} className='form-field-error mb-0 py-1 mt-2'>
								{msjValidacion}
							</Alert>
						}
					</Col>
				</Form.Group>
				<Form.Group as={Row}>
					<Form.Label className='d-flex mandatory-label' column sm='3'>
						<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
						Organismo:
					</Form.Label>
					<Col sm='8'>
						<DropdownList
							dataKey='id'
							filter='contains'
							placeholder='Seleccione un organismo...'
							data={organismList}
							allowCreate='onFilter'
							textField='nameToShow'
							as='select'
							disabled={onlyRead}
							defaultValue={props?.data && selectedOrganism?.id}
							onChange={value => handleOrganismOnChange(value?.id)}
							messages={messagesDropDown}
							selectIcon={<FontAwesomeIcon   icon={faChevronDown} className='text-black-color' />}
							/*searchIcon={<FontAwesomeIcon   icon={faSearch} className='text-black-color' />}*/
						>
						</DropdownList>
						<FormFieldError errors={errors?.codeOrganism} />
						<Form.Control hidden readOnly ref={register(organismDropDownObjValidation)} name='codeOrganism' value={codeOrganism} />
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label className='d-flex mandatory-label' column sm='3'>
						<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
						Número:
					</Form.Label>
					<Col sm='6'>
						<Form.Control
							disabled={onlyRead}
							type='number'
							defaultValue={props?.data?.number}
							name='number'
							ref={register(numberValidation)}
						/>
						<FormFieldError errors={errors?.number} />
					</Col>
					<Col sm='2'>
						<Form.Control
							as='select'
							name='year'
							ref={register(yearValidation)}
							disabled={onlyRead}
							defaultValue={props?.data?.year}
						>
							<option value={''}>Año</option>
							{
								yearsOptions?.map((item, idx) => (<option key={idx} value={item}>
									{item}
								</option>
								))
							}
						</Form.Control>
						<FormFieldError errors={errors?.year} />
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label className='d-flex mandatory-label' column sm='3'>
						<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
						Fecha:
					</Form.Label>
					<Col sm='8'>
						<Form.Control
							type='date'
							disabled={onlyRead}
							defaultValue={props?.data?.receiptDate}
							name='receiptDate'
							ref={register(receiptDateObjValidation)}
							onChange={onSelectDate}
						/>
						<FormFieldError errors={errors?.receiptDate} />
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label className='d-flex mandatory-label' column sm='3'>
						<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
						Descripcion:
					</Form.Label>
					<Col sm='8'>
						<Form.Control
							name='description'
							disabled={onlyRead}
							defaultValue={props?.data?.description}
							ref={register(descriptionValidation)}
							maxLength='255'
						/>
						<FormFieldError errors={errors?.description} />
					</Col>
				</Form.Group>

				<Form.Group as={Row} className={errors?.ticketAmount && 'mb-0'}>
					<Form.Label className='text-right d-flex mandatory-label' column sm='3'>
						<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
						Factura / Remito:
					</Form.Label>
					<Col sm='3'>
						<Form.Control
							name='ticket'
							disabled={onlyRead}
							defaultValue={props?.data?.ticket}
							onChange={(e) => setTicket(e.target.value)}
							value={ticket}
						/>
					</Col>
					<Form.Label className='text-right d-flex mandatory-label' column sm='2'>
						<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
						Importe
					</Form.Label>
					<Col sm='3'>
						<NumericFormat
							className="form-control text-right"
							name='amount'
							disabled={onlyRead}
							inputMode="numeric"
							value={amount}
							placeholder='ej: $1.234,56'
							defaultValue={props?.data?.amount}
							onChange={(e) => setAmount(e.target.value)}
							thousandSeparator='.' decimalSeparator=','
							decimalScale='2'
						/>
					</Col>
					<ActionIcon className="ml-2" size='lg' toolTipText="Cargar Factura" disabled={!ticket || !amount} icon={faPlus} onClick={ticketHandler} />
				</Form.Group>

				<Form.Group as={Row} hidden={!errors?.ticketAmount}>
					<Form.Label className='text-right d-flex mandatory-label' column sm='3'>
						&nbsp;
					</Form.Label>
					<Col sm='8'>
						<FormFieldError errors={errors?.ticketAmount} />
						<Form.Control hidden readOnly ref={register({ required: "Debe ingresar una factura" })} name='ticketAmount' value={props?.ticketObj?.ticketData} />
					</Col>
				</Form.Group>
				{(props?.data || isNotEmptyArray(props?.ticketObj?.ticketData)) &&
					<TicketTable
						ticketObj={props?.ticketObj}
						data={props?.data}
						onlyRead={onlyRead}
					/>
				}

				<Form.Group as={Row}>
					<Form.Label className='d-flex mandatory-label' column sm='3'>
						<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
						Multa:
					</Form.Label>
					<Col sm='8'>
						<NumericFormat
							className="form-control text-right"
							name='penaltyFee'
							inputMode="numeric"
							disabled={onlyRead}
							defaultValue={props?.data?.penaltyFee || 0}
							placeholder='ej: $1.234,56'
							thousandSeparator='.' decimalSeparator=','
							decimalScale='2'
							isAllowed={(inputObj) => withValueLimit(inputObj)}
							value={penaltyFeeValue}
							onChange={(e) => {
								setPenaltyFeeValue(e.target.value);
								delete errors?.penaltyFee
							}
							}
						/>
						<FormFieldError errors={errors?.penaltyFee} />
						<Form.Control hidden readOnly ref={register(penaltyFeeValidation)} name='penaltyFee' value={penaltyFeeValue} />
					</Col>
				</Form.Group>
			</Card.Body>
		</Card>

		<Form.Control hidden readOnly ref={register} name='type' value={tipo} />
		{!onlyRead &&
			<Form.Group controlId="formBasicCheckbox">
				<Form.Check 
					type="checkbox" 
					label="Incluir Orden de provisión" 
					onChange={e => setShowProvisionOrderCard(e.target.checked)} 
				/>
			</Form.Group>
		}
		{showProvisionOrderCard && (
			<Card variant='success' className='my-0'>
				<Card.Header>
					<h6 className='mb-0 text-center text-white'>
						Orden de Provisión
					</h6>
				</Card.Header>
				<Card.Body>
					{ hasAssociatedProvisionOrders &&
						<Form.Group as={Row}>
							<Form.Label className='d-flex mandatory-label' column sm='3'>
								<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
								Orden:
							</Form.Label>
							<Col sm='8'>
								<DropdownList
									filter='contains'
									placeholder='Seleccione una Orden de Provisión...'
									data={associatedProvisionOrders}
									allowCreate='onFilter'
									name='provisionOrderIdByCuit'
									textField='name'
									dataKey='id'
									onChange={item => handleActReceptionProvisionOrderByCuit(item)}
									value={provisionOrderId}
									className='w-100'
									disabled={onlyRead}
									messages={messagesDropDown}
									selectIcon={<FontAwesomeIcon size="sm" icon={faChevronDown} className='text-black-color' />}
									/*searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color' />}*/
								>
								</DropdownList>
								<FormFieldError errors={errors?.provisionOrderId} />
								<Form.Control hidden readOnly ref={register(provisionOrderByCuitValidationObj)} name='provisionOrderId' value={provisionOrderId} />
								<Form.Control hidden readOnly ref={register} name='initiator' value={initiator} />
							</Col>
						</Form.Group>
					}
					<Form.Group as={Row}>
						<Form.Label className='d-flex mandatory-label' column sm='3'>
							<FontAwesomeIcon icon={faAsterisk} size='xs' className={hasAssociatedProvisionOrders ? 'hidden' : 'mr-1 text-danger mandatory-asterisk'} />
							Servicio:
						</Form.Label>
						<Col sm='8'>
							<DropdownList
								filter='contains'
								placeholder='Seleccione un servicio...'
								data={administrativeServiceData}
								allowCreate='onFilter'
								name='admService'
								textField='displayName'
								dataKey='id'
								onChange={item => handleActReceptionService(item.id)}
								value={tempAdmServiceId}
								className='w-100'
								disabled={onlyRead || disableByProvisionOrder}
								messages={messagesDropDown}
								selectIcon={<FontAwesomeIcon size="sm" icon={faChevronDown} className='text-black-color' />}
								/*searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color' />} >*/
							>
							</DropdownList>
							<FormFieldError errors={errors?.admService} />
							<Form.Control hidden readOnly ref={register(serviceValidationObj)} name='admService' value={tempAdmServiceId} />
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label className='d-flex mandatory-label' column sm='3'>
							<FontAwesomeIcon icon={faAsterisk} size='xs' className={hasAssociatedProvisionOrders ? 'hidden' : 'mr-1 text-danger mandatory-asterisk'} />
							Número:
						</Form.Label>
						<Col sm='8'>
							<Form.Control
								type='number'
								name='orderProvisionNumber'
								disabled={onlyRead || disableByProvisionOrder}
								value={provisionOrderNumber}
								onChange={event => handlerProvionOrderNumber(event.target.value)}
							/>
							<Form.Control hidden readOnly ref={register(provisionOrderNumberValidation)} name='orderProvisionNumber' value={provisionOrderNumber} />
							<FormFieldError errors={!hasAssociatedProvisionOrders && errors?.orderProvisionNumber} />
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label className='d-flex mandatory-label' column sm='3'>
							<FontAwesomeIcon icon={faAsterisk} size='xs' className={hasAssociatedProvisionOrders ? 'hidden' : 'mr-1 text-danger mandatory-asterisk'} />
							Año:
						</Form.Label>
						<Col sm='8'>
							<Form.Control
								className='text-left'
								as='select'
								disabled={onlyRead || disableByProvisionOrder}
								name='orderProvisionYear'
								value={provisionOrderYear}
								onChange={event => handlerProvionOrderYear(event.target.value)}
							>
								<option value={''}>Seleccione un año...</option>
								{ yearsOptions?.map((item, idx) => (
									<option key={idx} value={item}>
										{item}
									</option>
								))}
							</Form.Control>
							<Form.Control hidden readOnly ref={register(provisionOrderYearValidation)} name='orderProvisionYear' value={provisionOrderYear} />
							<FormFieldError errors={!hasAssociatedProvisionOrders && errors?.orderProvisionYear} />
						</Col>
					</Form.Group>
				</Card.Body>
			</Card>

)}
	</>
};

export default ModalReceptionAct;