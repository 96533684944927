import React, { useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { push } from 'connected-react-router';
import { ALERT_LIST } from 'src/utils/constants';

import FormFieldError from 'src/components/general/FormFieldError';
import { alert, level, dateSince, dateUntil, active, profile, cancel, save, } from 'src/utils/label';
import { tryGetListUserProfile, } from 'src/redux/user/userActionCreator';
import { getUserProfileListData } from 'src/redux/user/userReducer';
import { getAlertCreateIsFetching, getAlertEditIsFetching} from 'src/redux/alert/alertReducer';

const AlertForm = props => {

	const dispatch = useDispatch();
    const { register, errors, alertToEdit } = useFormContext();

	const alertCreateIsFetching = useSelector(state => getAlertCreateIsFetching(state));
	const alertEditIsFetching = useSelector(state => getAlertEditIsFetching(state));
	
	const [messageStart, setMessageStart] = useState(alertToEdit?.messageStart ? moment(alertToEdit?.messageStart).format('YYYY-MM-DDThh:mm') : null);
	const [messageEnd, setMessageEnd] = useState(alertToEdit?.messageEnd ? moment(alertToEdit?.messageEnd).format('YYYY-MM-DDThh:mm') : null);

	let dateControl = (messageStart || messageEnd);

	const [checkDate, setCheckDate] = useState(dateControl == null ? false : true);

	const [messageError, setMessageError] = useState(false);
	
	const [sinceDateValidation, setSinceDateValidation] = useState(alertToEdit?.messageStart);
	const [untilDateValidation, setUntilDateValidation] = useState(alertToEdit?.messageEnd);

	useEffect( () => {
		setMessageError(( sinceDateValidation > untilDateValidation) ? true : false);
	}, [sinceDateValidation, untilDateValidation] );

	const clickCheckDategHandler = () => {
		setCheckDate(!checkDate);
	};

    //validations
	const levelRequiredMsg = 'Debe seleccionar el nivel.';
	const messageAlertRequiredMsg = 'Debe ingresar la alerta.';
	const messageActiveRequiredMsg = 'Debe seleccionar el activo.';
	
	const levelValidationObj = { required: levelRequiredMsg };
	const activeValidationObj = { required: messageActiveRequiredMsg };
	let maxCharacteres = 100;
	let minCharacteres = 5;
	const messageAlertValidationObj = { 
		required: messageAlertRequiredMsg, 
		maxLength: {
			value: maxCharacteres,
			message: `La alerta no debe superar el máximo de ${maxCharacteres} letras.`
		},
		minLength: {
			value: minCharacteres,
			message: `La alerta debe tener un mínimo de ${minCharacteres} letras.`
		}
	};

	// Profile
	const listProfileData = useSelector( state => getUserProfileListData(state) )?.sort((a, b) => a.name > b.name ? 1 : -1);

	useEffect( () => {
		dispatch( tryGetListUserProfile() );
	}, [] );

	const levelStatusAlert = [
		{
			"id": "DANGER",
			"name": "CRÍTICO"
		},
		{
			"id": "WARNING",
			"name": "ADVERTENCIA"
		},
		{
			"id": "INFO",
			"name": "INFORMACIÓN"
		}
	];
	
	const trueFalseStatus = [
		{
			"id": true,
			"name": "SI"
		},
		{
			"id": false,
			"name": "NO"
		}
	];

	return <>

		<Form.Group as={Row} controlId='alias'>
			<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
				<Form.Check 
					type="checkbox"
					id="passiveRemaining"
					label="Activar rangos de fechas"
					className="text-black-color"
					onChange={clickCheckDategHandler}
					checked={checkDate}
				/>
			</Form.Label>
		</Form.Group>

		<Form.Group as={Row} controlId='alias'>
			<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4} >
					{dateSince}:
			</Form.Label>
			<Col sm={4}>
				<Form.Control
					ref={register}
					type="datetime-local"
					name='dateSince'
					title={dateSince}
					defaultValue={messageStart}
					disabled={!checkDate}
					onChange={ event => setSinceDateValidation(event.target.value)  }
				/>
				<FormFieldError errors={errors?.dateSince} />
			</Col>
		</Form.Group>

		<Form.Group as={Row} controlId='alias'>
			<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
					{dateUntil}:
			</Form.Label>
			<Col sm={4}>
				<Form.Control
					ref={register}
					type="datetime-local"
					name='dateUntil'
					title={dateUntil}
					defaultValue={messageEnd} 
					disabled={!checkDate}
					onChange={ event => setUntilDateValidation(event.target.value) }
				/>
				<FormFieldError errors={errors?.dateUntil} />
				{ 
					messageError &&
						<div className="alert alert-danger form-field-error mb-0 py-1 mt-1" role="alert">
							{'La fecha desde es superior a la fecha hasta.'}
						</div>
				}
			</Col>
		</Form.Group>

		<Form.Group as={Row} controlId='alias' className='text-black-color' >
			<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
				<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
					{level}:
			</Form.Label>
			<Col sm={4}>
				<Form.Control
					as='select'
					name='level'
					title={level}
					ref={register(levelValidationObj)}
					defaultValue={alertToEdit?.level} 
				>
				<option value={''} selected>Seleccionar</option>
					{
						levelStatusAlert?.map(item => (
						<option key={item?.id} value={item?.id}>
							{item?.name}
						</option>))
					}
				</Form.Control>
				<FormFieldError errors={errors?.level} />
			</Col>
		</Form.Group>

		<Form.Group as={Row} controlId='alias' className='text-black-color' >
			<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
				<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
					{active}:
			</Form.Label>
			<Col sm={4}>
				<Form.Control
					as='select'
					name='active'
					title={active}
					ref={register(activeValidationObj)}
					defaultValue={alertToEdit?.active} 
				>
				<option value={''} selected>Seleccionar</option>
					{
						trueFalseStatus?.map(item => (
						<option key={item?.id} value={item?.id}>
							{item?.name}
						</option>))
					}
				</Form.Control>
				<FormFieldError errors={errors?.active} />
			</Col>
		</Form.Group>

		<Form.Group as={Row} controlId='alias'  className='text-black-color' >
			<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
					{profile}:
			</Form.Label>
			<Col sm={4}>
			<Form.Control
				as='select'
				name='profile'
				title={profile}
				ref={register()}
				defaultValue={alertToEdit?.profile}
			>
			<option value={''} selected>Todos</option>
				{
					listProfileData?.map(item => (
					<option key={item?.id} value={item?.name}>
						{item?.name}
					</option>))
				}
			</Form.Control>
				<FormFieldError errors={errors?.profile} />
			</Col>
		</Form.Group>

		<Form.Group as={Row} controlId='alias'>
			<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
				<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
					{alert}:
				</Form.Label>
				<Col sm={4}>
					<Form.Control
                        ref={register(messageAlertValidationObj)}
                        as='textarea'
                        placeholder={alert}
                        name='message'
                        disabled={false}
                        defaultValue={alertToEdit?.message} 
                        maxLength={maxCharacteres+1}
					/>
				<FormFieldError errors={errors?.message} />
				</Col>
		</Form.Group>

		<div className='d-flex justify-content-around mt-4 mb-3'>
			<Button 
				variant='danger' 
				size='lg'
				disabled={alertCreateIsFetching} 
				onClick={() => dispatch(push(ALERT_LIST))}
			>
				{cancel}
			</Button>

			<span className={ ( (alertCreateIsFetching || alertEditIsFetching) ? '' : 'hidden') }>
				<Spinner className='spinner-border text-danger' animation='border' />
			</span>

			<Button 
				type='submit' 
				variant='success' 
				size='lg'
				disabled={alertCreateIsFetching || alertEditIsFetching || messageError}
			>
				{save}
			</Button>
		</div>
		
    </>
};

export default AlertForm;