import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Table, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSearch } from '@fortawesome/free-solid-svg-icons';

import { ORDER_PAY_LIST, TIPO_RECIBO } from 'src/utils/constants';
import { formatterPeso, mFormat, getOrderPayTypeValueToShow, removeDuplicateElements, clearNullValuesFromArr, organismsFilter } from 'src/utils/utils';

import { getOrderPayDetailID, getAffectationsMovementsByIDOrderPayListIsFetching } from 'src/redux/orderPay/orderPayReducer';
import { getAdministrativeServiceByIdData } from 'src/redux/administrativeService/administrativeServiceReducer';
import { tryGetOrganismAdministrativeServices, trySearchAdministrativeServiceById } from '../../../redux/administrativeService/administrativeServiceActionCreator';
import { clearDetailOrderPayData } from 'src/redux/orderPay/orderPayActions';
import { tryOrderPayById } from 'src/redux/orderPay/orderPayActionCreator';
import { NumericFormat } from 'react-number-format';
import TableReceiptsGenerator from 'src/components/pages/OrderPay/TableReceiptsGenerator';
import { isNotEmptyArray } from 'src/services/validationService';
import { getGlobalDataPeriodsData } from 'src/redux/globalData/globalDataReducer';
import { setOrderPayToDetail } from '../../../redux/orderPay/orderPayActions';
import ActionIcon from '../../general/ActionIcon';
import DetailModal from '../OrderPay/modal/DetailModal';
import AppLoading from 'src/components/common/AppLoading';
import { getAdministrativeServiceOrganismListData, getAdministrativeServiceOrganismListIsFetching } from 'src/redux/administrativeService/administrativeServiceReducer';
import { useParams } from "react-router-dom";

const OrderPayDetailsPage = () => {
	const { paymentOrderId, serviceId } = useParams();

	const dispatch = useDispatch();

	const orderPayDetailData = useSelector(state => getOrderPayDetailID(state));
	const administrativeService = useSelector(state => getAdministrativeServiceByIdData(state));
	const [pedidoFondo, setpedidoFondo] = useState();
	
	const [admCode, setAdmCode] = useState(orderPayDetailData?.administrativeDocument?.codeOrganism);
	const [admNumber, setAdmNumber] = useState(orderPayDetailData?.administrativeDocument?.number);
	const [admYear, setAdmYear] = useState(orderPayDetailData?.administrativeDocument?.year);

	const periodsData = useSelector(state => getGlobalDataPeriodsData(state))?.data?.sort((a, b) => a.year < b.year ? 1 : -1);

	const OPHaberes = orderPayDetailData?.type == "ORDEN_DE_PAGO_DE_HABERES";
    const OPProveedores = orderPayDetailData?.type == 'ORDEN_DE_PAGO_DE_PROVEEDORES';
	const conditionToShowCreditor = orderPayDetailData?.type === 'ORDEN_DE_PAGO_DE_PROVEEDORES';
	const conditionToWage = orderPayDetailData?.type === 'ORDEN_DE_PAGO_DE_HABERES';
	const showObservations = OPHaberes || OPProveedores;

	const [show, setShow] = useState(false);
	const [activeOP, setActiveOP] = useState(false);

	const affectationsMovementsByIDOrderPayListIsFetching = useSelector(state => getAffectationsMovementsByIDOrderPayListIsFetching(state));

	const administrativeServiceOrganismListIsFetching = useSelector(state => getAdministrativeServiceOrganismListIsFetching(state));
	const organismList = useSelector(state => getAdministrativeServiceOrganismListData(state));

	const matchYearId = (periodsData, year) => {
		return periodsData?.find((a) => a.year === year)?.id
	}

	//grouping of receipts and supplier injection by receipt
	let receiptsCuitArray = [];

	orderPayDetailData?.beneficiaries?.map(beneficiarie => {
		beneficiarie?.receipts?.map(receipt => {
			let receitCuit = {
				...receipt,
				cuit: beneficiarie?.persona?.cuit?.toString(),
				companyName: beneficiarie?.persona?.companyName,
				beneficiaryValidator: beneficiarie?.persona?.beneficiaryValidator,
				providerNumber: beneficiarie?.persona?.providerNumber
			};
			receiptsCuitArray.push(receitCuit);
		});
	});

	useEffect(() => {
		dispatch(clearDetailOrderPayData());
		// const administrativeserviceId = orderPayDetailData.administrativeServiceId
		// dispatch(trySearchAdministrativeServiceById(administrativeserviceId))
		dispatch(trySearchAdministrativeServiceById(serviceId))
		// const afectationsId = orderPayDetailData?.details?.map(order => {
		// 	return order.affectationMovementId
		// });
		// dispatch(tryGetOrganismAdministrativeServices(administrativeserviceId, matchYearId(periodsData, orderPayDetailData?.administrativeDocument?.year)))
		dispatch(tryGetOrganismAdministrativeServices(serviceId, matchYearId(periodsData, orderPayDetailData?.administrativeDocument?.year)))
		// dispatch(tryOrderPayById(orderPayDetailData.id)).then((response) => {
		dispatch(tryOrderPayById(paymentOrderId)).then((response) => {
			response?.legalInstruments?.push(response?.legalInstrument)
			dispatch(setOrderPayToDetail(response));
			setpedidoFondo(response?.fundRequest);
		});
	}, []);

	const onClickBackButton = () => {
		dispatch(push(ORDER_PAY_LIST));
	};

	const onClickDetail = item => {
		setActiveOP(item.paymentOrder);
		setShow(true);
	};

	const calcularTotal = () => {
		let totalLocal = 0;
		orderPayDetailData?.details?.map(item => {
			totalLocal += item?.presupuestoResponse.amount || 0
		});
		return totalLocal;
	};
	let total = calcularTotal();


	return <>
		<>
			<Container fluid>
				<Card className='mb-5'>
					<Card.Header className='h6'>
						Detalle Orden de Pago
					</Card.Header>
					<Card.Body>
						<Table className='text-black-color' striped borderless hover>
							<tbody>
								<tr>
									<td className='text-center font-weight-bold' width='40%'>
										Tipo:
									</td>
									<td className='text-center maxTruncate'>
										{getOrderPayTypeValueToShow(orderPayDetailData?.type)}
									</td>
								</tr>
								<tr>
									<td className='text-center font-weight-bold' width='40%'>
										Servicio:
									</td>
									<td className='text-center maxTruncate'>
										{`${administrativeService?.code} - ${administrativeService?.name}`}
									</td>
								</tr>
								<tr>
									<td className='text-center font-weight-bold' width='40%'>
										Expediente:
									</td>
									<td className='text-center maxTruncate'>
										{orderPayDetailData?.administrativeDocument?.codeOrganism}-{orderPayDetailData?.administrativeDocument?.number} / {orderPayDetailData?.administrativeDocument?.year} - {orderPayDetailData?.administrativeDocument?.title || 'S/D'}
									</td>
								</tr>
								<tr>
									<td className='text-center font-weight-bold' width='40%'>
										Número:
									</td>
									<td className='text-center maxTruncate'>
										{orderPayDetailData?.number} - {orderPayDetailData?.year}
									</td>
								</tr>
								<tr>
									<td className='text-center font-weight-bold' width='40%'>
										Fecha:
									</td>
									<td className='text-center maxTruncate'>
										{mFormat(orderPayDetailData?.datePaymentOrder)}
									</td>
								</tr>
								<tr>
									<td className='text-center font-weight-bold' width='40%'>
										Descripción:
									</td>
									<td className='text-center maxTruncate'>
										{orderPayDetailData?.description}
									</td>
								</tr>
								{ showObservations &&
									<tr>
										<td className='text-center font-weight-bold' width='40%'>
											Observaciones:
										</td>
										<td className='text-center maxTruncate'>
											{orderPayDetailData?.observations ? orderPayDetailData?.observations : '-'}
										</td>
									</tr>
								}
								<tr>
									<td className='text-center font-weight-bold'  width='40%'>
										Importe:
									</td>
									<td className='text-center maxTruncate'>
										{formatterPeso.format(total)}
									</td>
								</tr>
								{conditionToWage &&
									<tr>
										<td className='text-center font-weight-bold' width='40%'>
											Liquidación:
										</td>
										<td className='text-center'>
											Consultar detalle de Liquidación &nbsp;
											<ActionIcon className='text-right' toolTipText='Ver detalle' icon={faSearch} onClick={() => onClickDetail(orderPayDetailData)} />
										</td>

									</tr>
								}
							</tbody>
						</Table>

						{isNotEmptyArray(orderPayDetailData?.beneficiaries) &&
							<>
								<p className='text-black-color h6 mt-4' >
									{TIPO_RECIBO[orderPayDetailData?.beneficiaries?.[0]?.receipts?.[0]?.type]}:
								</p>
								<TableReceiptsGenerator
									receipts={receiptsCuitArray}
									tipo={orderPayDetailData?.beneficiaries?.[0]?.receipts?.[0]?.type}
									orderPayDetailData={orderPayDetailData}
									admCode={admCode}
									admNumber={admNumber}
									admYear={admYear}
									_hookFormMethodsModal={{
										organismList: organismsFilter(organismList)
									}}
								/>
							</>
						}

						{isNotEmptyArray(clearNullValuesFromArr(orderPayDetailData?.legalInstruments)) &&
							<>
								<p className='text-black-color h6 mt-4' >
									Instrumentos Legales:
								</p>
								<Table striped hover size="sm">
									<thead>
										<tr className='text-white-color'>
											<th className='text-center' width="15%">Instrumento</th>
											<th className='text-center' width="15%">Nro.</th>
											<th className='text-center' width="15%">Año</th>
										</tr>
									</thead>
									<tbody className='text-black-color'>
										{removeDuplicateElements(orderPayDetailData?.legalInstruments, "number")?.map((item) => (
											<tr key={item?.number}>
												<td className='text-center maxTruncate'>
													{item?.legalInstrumentType?.name}
												</td>
												<td className='text-center maxTruncate'>
													{item?.number}
												</td>
												<td className='text-center maxTruncate'>
													{item?.year}
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							</>
						}

						<p className='text-black-color h6 mt-4' >
							Afectaciones:
						</p>
						{!affectationsMovementsByIDOrderPayListIsFetching ? (
							<Table striped hover size='sm' responsive>
								<thead>
									<tr>
										<th className='text-center font-weight-bold' width="35%">Nro. Partida</th>
										<th className='text-center font-weight-bold' width="35%">Denominación</th>
										<th className='text-center font-weight-bold' width="20%">Monto</th>
										<th className='text-center font-weight-bold' width="10%">RP</th>
									</tr>
								</thead>
								<tbody className='text-black-color'>
									{
										orderPayDetailData?.details?.map(item => (
											<tr key={item?.presupuestoResponse?.id}>
												<td className='text-center py-1 maxTruncate'>
													<div>{item?.presupuestoResponse?.subCodeCompleteNumber}</div>
												</td>
												<td className='text-center py-1 maxTruncate'>
													{item?.presupuestoResponse?.description}
												</td>
												<td className='text-center py-1 maxTruncate'>
													{formatterPeso.format(item?.presupuestoResponse?.amount)}
												</td>
												<td className='text-center py-1 maxTruncate'>
													<b>{item?.presupuestoResponse?.periodRP}</b>
												</td>
											</tr>
										))
									}
								</tbody>
							</Table>
						) : (
							<div className='text-center'>
								<Spinner animation='border' variant='success' size='sm' />
							</div>
						)}

						{
							pedidoFondo ?
								<>
									<p className='text-black-color h6 mt-4' >
										Pedido de Fondo:
									</p>
									<Table striped borderless hover>
										<thead>
											<tr>
												<th className='text-center font-weight-bold' width="15%">Ejercicio</th>
												<th className='text-center font-weight-bold' width="15%">Número</th>
												<th className='text-center font-weight-bold' width="10%">Fecha</th>
												<th className='text-center font-weight-bold' width="60%">Descripción</th>
											</tr>
										</thead>
										<tbody className='text-black-color'>
											<tr>
												<td className='text-center py-1 maxTruncate'>
													{pedidoFondo?.year}
												</td>
												<td className='text-center py-1 maxTruncate'>
													{pedidoFondo?.number}
												</td>
												<td className='text-center py-1 maxTruncate'>
													{mFormat(pedidoFondo?.fundRequestDate)}
												</td>
												<td className='text-center py-1 maxTruncate'>
													{pedidoFondo?.description}
												</td>
											</tr>
										</tbody>
									</Table>
								</>
								:
								'Sin detalle de un Pedido de Fondo'
						}
						<div className='text-center'>
							<Button variant='primary' size='lg' className='my-3' onClick={onClickBackButton}>
								<FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
								Volver
							</Button>
						</div>
					</Card.Body>
				</Card>
			</Container>
		</>
		<AppLoading isLoading={(administrativeServiceOrganismListIsFetching && conditionToShowCreditor)} />
		<DetailModal
			showPayOrderDetailModal={show}
			setShowPayOrderDetailModal={setShow}
			activePayOrder={activeOP}
			selectedOrderPay={orderPayDetailData}
		/>
	</>
};

export default OrderPayDetailsPage;