import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useForm } from 'react-hook-form';
import { Container, Card, Form, Spinner, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faAsterisk } from '@fortawesome/free-solid-svg-icons';
import PageTitle from 'src/components/general/PageTitle';
import { parseIntOrUndefined } from 'src/utils/utils'
import { tryListAdministrativeserviceTypes, tryPutAdministrativeService } from 'src/redux/administrativeService/administrativeServiceActionCreator';
import { getAdministrativeServiceTypesData, getAdministrativeServiceCreateIsFetching, getAdministrativeServiceTypeByAdministrativeServiceId } from 'src/redux/administrativeService/administrativeServiceReducer';
import FormFieldError from 'src/components/general/FormFieldError';
import { SERVICE_ADMINISTRATIVE_LIST } from 'src/utils/constants';
import { abbreviation, code, description, administrativeServiceOrganismCode } from 'src/utils/label';
import { getAdministrativeServiceToEdit } from 'src/redux/administrativeService/administrativeServiceReducer'
import ServiceAdminOrgCodeBox from 'src/components/forms/serviceAdministrative/ServiceAdminOrgCodeBox'

const ServiceAdministrativeEditPage = props => {

    const dispatch = useDispatch();
    const { register, handleSubmit, errors } = useForm();

    // Validations
    const codeRequiredMsg = 'Debe ingresar el código del Servicio';
    const descriptionRequiredMsg = 'Debe ingresar una descripción';
    const abbreviationRequiredMsg = 'Debe ingresar una Abreviatura';
    const administrativeServiceTypeMsg = 'Debe elegir un tipo de servicio';
    // Form data
    const codeValidationObj = { required: codeRequiredMsg };
    const descriptionValidationObj = { required: descriptionRequiredMsg };
    const abbreviationValidationObj = { required: abbreviationRequiredMsg }
    const administrativeServiceTypeValidationObj = {
        validate: value => {
            if (!value) {
                return administrativeServiceTypeMsg
            }

        }
    }

    const administrativeServiceToEdit = useSelector(state => getAdministrativeServiceToEdit(state));
    const administrativeServiceType = useSelector(state => getAdministrativeServiceTypeByAdministrativeServiceId(state, administrativeServiceToEdit?.administrativeServiceType?.id));
    const listTypeServiceData = useSelector(state => getAdministrativeServiceTypesData(state))?.records;
    const administrativeServiCreateIsFetching = useSelector(state => getAdministrativeServiceCreateIsFetching(state));

    const [ descriptionEmpty, setDescriptionEmpty ] = useState(false);
    const [ shortNameEmpty, setshortNameEmpty ] = useState(false);

    //ServiceCode
    const [serviceOrganismCodeSelected, setServiceOrganismCodeSelected] = useState(administrativeServiceToEdit?.organismCodes);

    const onChangeDescriptionInput = () => {
        setDescriptionEmpty(false);
    };
    
    const onChangeShortNameInput = () => {
        setshortNameEmpty(false);
    };

    const onFormSubmit = data => {

        data.code = parseIntOrUndefined(data.code)
        data.administrativeServiceType = listTypeServiceData.find(item => item.id == data.administrativeServiceType)

         //Trim data
         data.name = data?.name?.replace(/^\s+|\s+$/g,'');
         data.shortName = data?.shortName?.replace(/^\s+|\s+$/g,'');
         data.organismCodes = serviceOrganismCodeSelected

         if (data?.name?.length === 0){
             setDescriptionEmpty(true)
             return 0;
         }  
 
         if (data?.shortName?.length === 0){
             setshortNameEmpty(true)
             return 0;
         }  

        dispatch(tryPutAdministrativeService(administrativeServiceToEdit?.id, data)).then(response => {
            if (response.status == 200) {
                dispatch(push(SERVICE_ADMINISTRATIVE_LIST));
            }
        })
    };
    useEffect(() => {
        dispatch(tryListAdministrativeserviceTypes());
    }, []);
    return <Container className='mb-5'>
        <Card>
            <PageTitle text='Servicios' />
            <Container fluid>
                <Card className='mb-3'>
                    <Card.Header>
                        <h6 className='mb-0'>
                            Editar servicio
						</h6>
                    </Card.Header>
                    <Card.Body >
                        <Row>
                            <Col xs={8}>
                                <h6 className='mb-4 text-black-color'>
                                    Datos del servicio
								</h6>
                            </Col>
                            <Col xs={4} className='text-right font-weight-bold font-italic'>
                                <span className='text-danger d-flex mandatory-label'>
                                    <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
									Obligatorio
								</span>
                            </Col>
                        </Row>
                        <Form className='mt-4' onSubmit={handleSubmit(onFormSubmit)}>
                            <Form.Group as={Row} controlId='formHorizontalEmail'>
                                <Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
                                    <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
									Código:
								</Form.Label>
                                <Col sm={4}>
                                    <Form.Control 
                                        ref={register(codeValidationObj)} 
                                        type='number' 
                                        placeholder={code} 
                                        name='code' 
                                        id='code' 
                                        disabled={administrativeServiCreateIsFetching} 
                                        defaultValue={`${administrativeServiceToEdit?.code}`} 
                                        min='1'
                                        max='9999'
                                        required
                                    />
                                    <FormFieldError errors={errors?.code} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId='formHorizontalEmail'>
                                <Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
                                    <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
									Descripción:
								</Form.Label>
                                <Col sm={4}>
                                    <Form.Control 
                                        ref={register(descriptionValidationObj)} 
                                        type='text' 
                                        placeholder={description} 
                                        name='name' 
                                        id='name' 
                                        disabled={administrativeServiCreateIsFetching} 
                                        defaultValue={`${administrativeServiceToEdit?.name}`}
                                        maxLength='255'
                                        onChange={onChangeDescriptionInput}
                                        required 
                                    />
                                    <FormFieldError errors={errors?.name} />
                                    {
										descriptionEmpty
										?
											<>
											<div className='alert alert-danger form-field-error mb-0 py-1 mt-1' role='alert'>
											{'El campo no debe estar vacío.'}
											</div>
											</>
										:
										null
									}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId='formHorizontalEmail'>
                                <Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
                                    <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                                    {abbreviation}:
                                </Form.Label>
                                <Col sm={4}>
                                    <Form.Control 
                                        ref={register(abbreviationValidationObj)} 
                                        type='text' 
                                        placeholder={abbreviation} 
                                        name='shortName' 
                                        id='shortName' 
                                        defaultValue={`${administrativeServiceToEdit?.shortName}`}
                                        maxLength='255'
                                        onChange={onChangeShortNameInput}
                                        required  
                                    />
                                    <FormFieldError errors={errors?.shortName} />
                                    {
										shortNameEmpty && !errors?.shortName
										?
											<>
											<div className='alert alert-danger form-field-error mb-0 py-1 mt-1' role='alert'>
											{'El campo no debe estar vacío.'}
											</div>
											</>
										:
										null
									}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
                                    <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
									Tipo de servicio:
								</Form.Label>
                                <Col sm='4'>
                                    <Form.Control as='select' name='administrativeServiceType' ref={register(administrativeServiceTypeValidationObj)} defaultValue={administrativeServiceType?.id}  >
                                       {/*  <option className='text-black-color' value={undefined} disabled={getValues("administrativeServiceType")}>
                                            Seleccione una Opción
                                        </option> */}
                                        {
                                            listTypeServiceData?.map(profile =>
                                                <option className='text-black-color' key={profile.id}value={profile.id} >
                                                    {`${profile.name || ''}`}
                                                </option>)
                                        }
                                    </Form.Control>
                                    <FormFieldError errors={errors?.administrativeServiceType} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId='formHorizontalEmail'>
                                <Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
									Código de Expediente:
								</Form.Label>
                                <Col sm={4}>
                                    <Form.Control 
                                        ref={register}
                                        type='number' 
                                        placeholder={administrativeServiceOrganismCode} 
                                        name='administrativeServiceOrganismCode' 
                                        id='administrativeServiceOrganismCode' 
                                        disabled={administrativeServiCreateIsFetching} 
                                        defaultValue={`${administrativeServiceToEdit?.administrativeServiceOrganismCode}`} 
                                        min='1000'
                                        max='9999'
                                    />
                                    <FormFieldError errors={errors?.administrativeServiceOrganismCode} />
                                </Col>
                            </Form.Group>
                            <ServiceAdminOrgCodeBox
                                    serviceOrganismCodeSelected={serviceOrganismCodeSelected}
                                    setServiceOrganismCodeSelected={setServiceOrganismCodeSelected}
                                />
                            <div className='d-flex justify-content-around mt-4 mb-3'>
                                <Button type='button' variant='danger' size='lg' disabled={false} onClick={() => dispatch(push(SERVICE_ADMINISTRATIVE_LIST))}>
                                    Cancelar
								</Button>
                                <span className={administrativeServiCreateIsFetching ? '' : 'hidden'}>
                                    <Spinner animation='border' />
                                </span>
                                <Button
                                    type='submit'
                                    variant='success'
                                    size='lg'
                                    disabled={administrativeServiCreateIsFetching}>
                                    Guardar
								<FontAwesomeIcon icon={faSave} className='ml-2' />
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </Card>
    </Container>
};
export default ServiceAdministrativeEditPage;