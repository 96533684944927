import { push } from 'connected-react-router';
import { config } from 'src/env.js';

import * as ACTIONS from './loginActions';
import {ROUTE_PATHS as ROUTES}  from 'src/routes';
import { showSuccess, showError, showWarning } from 'src/redux/globalData/globalDataActionCreator';
import * as SERVICES from 'src/services/loginServices';
import { logout } from 'src/redux/login/loginActions';

export const tryLogInAction = ( username, password ) => {
	return async ( dispatch, getState ) => {
		dispatch( ACTIONS.loginRequest( username, password ) );
		const response = await SERVICES.postLogin( username, password );

		dispatch( ACTIONS.statusApiRequest() );
		const responseStatus = await SERVICES.getStatusApiService();

		if( !response.hasOwnProperty("error") ) {
			// if (response.isPrimerLogin){
			if (response.firstLogin){
				//
				dispatch( ACTIONS.setPasswordPreRequest(response) );
				dispatch( push(ROUTES.SET_PASSWORD_URI) );
			}else{
				dispatch( push(ROUTES.INDEX_PAGE) );
				dispatch( ACTIONS.loginRequestSuccess(response) );
				dispatch( ACTIONS.statusApiRequestSuccess(responseStatus?.data));
				const status = responseStatus?.data?.records[0]?.active;
				if (status){
					showSuccess('¡Bienvenido!', `Ingresaste en ${config.appSettings.name}` );
				}else{
					showWarning('¡Aviso!, El sistema se encuentra en mantenimiento.' );
				}
			}
		}
		else {
			dispatch( ACTIONS.loginRequestFailure(response.error) );
			showError('Usuario y/o contraseña incorrecta.' );
		}
		return response;
	}
};

// Current Password
export const tryCurrentPassword = ( username, password ) => {
	return async ( dispatch, getState ) => {
		dispatch( ACTIONS.currentPasswordRequest( username, password ) );

		const response = await SERVICES.postCurrentPassword( username, password );

		if( !response.hasOwnProperty("error") ) {
			dispatch( push(ROUTES.INDEX_PAGE) );
			dispatch( ACTIONS.currentPasswordRequestSuccess(response) );
			showSuccess('Cerrando sesión.' );
		}
		else {
			// dispatch( ACTIONS.currentPasswordRequestFailure(response.error) );
			showError('Se ingresaron datos incorrectos. Por favor intente nuevamente.' );
		}
		return response;
	}
};

export const trySendForgotPasswordEmail = ( email, reset ) => {
	return async ( dispatch, getState ) => {

		dispatch( ACTIONS.forgotPasswordSendEmailRequest(email) );

        const response = await SERVICES.postForgotPasswordSendEmail(email);
		
		if( response?.status == 200 ) {
			dispatch( ACTIONS.forgotPasswordSendEmailRequestResolve() );
			showSuccess('Revise la bandeja de entrada de su correo electrónico.' );
		}
		else {
			showError(`Ocurrió un error al enviar el correo electrónico. Detalle: ${response?.error?.response?.data?.error?.message||response}` );
		}
		
		return response;

		reset ? reset() : false;
	};
};

// Restablecer contraseña por panel de usuario
export const trySendRestorePasswordEmail = ( email, reset ) => {
	return async ( dispatch, getState ) => {

		dispatch( ACTIONS.restorePasswordSendEmailRequest(email) );

        const response = await SERVICES.postRestorePasswordSendEmail(email);
		
		if( response?.status == 200 ) {
			dispatch( ACTIONS.restorePasswordSendEmailRequestResolve() );
			showSuccess('La contraseña del usuario: '+email+' ha sido reestablecida. Se ha enviado un correo electrónico al usuario informando la situación.' );
		}
		else {
			showError(`Ocurrió un error al enviar el correo electrónico para restablecer la contraseña. Detalle: ${response?.error||response}` );
		}
		
		return response;

	};
};

export const trySendResetPassword = ( password, token ) => {
	return async ( dispatch, getState ) => {
		dispatch( ACTIONS.resetPasswordRequest() );

		const response = await SERVICES.resetPassword( password, token );

		if( response?.status == 200 ) {
			dispatch( ACTIONS.resetPasswordRequestSuccess() );
			showSuccess('Se actualizó la contraseña correctamente.' );
			dispatch( push(ROUTES.LOGIN_URI) );
		}
		else {
			dispatch( ACTIONS.resetPasswordRequestFailure() );
			showError(`Ocurrió un error al actualizar la nueva contraseña. Detalle: ${response?.response?.response?.data?.error?.message}`)
		}
	};
};

// status api system
export const getStatusApi = (profileName) => {

	return async( dispatch ) => {
		
		dispatch( ACTIONS.statusApiRequest() );

		const response = await SERVICES.getStatusApiService();
		
		if( response?.status == 200 ) {
			dispatch( ACTIONS.statusApiRequestSuccess(response?.data));
			const status = response?.data?.records[0]?.active;
			if(status == false && profileName != "Super Admin"){
				dispatch( logout() );
				dispatch(push(ROUTES.LOGIN_URI));
			}
			return status;
		}
		else {
			dispatch( ACTIONS.statusApiRequestFailure(response?.data) );
			showError(`Ha ocurrido un error al comunicarse con las fuentes de información externas. Por favor intente nuevamente más tarde.` );
			return response;
		}

	};
};

// status api login
export const getStatusApiLogin = () => {
	return async( dispatch ) => {
		
		dispatch( ACTIONS.statusApiRequest() );

		const response = await SERVICES.getStatusApiService();
		
		if( response?.status == 200 ) {
			dispatch( ACTIONS.statusApiRequestSuccess(response?.data));
			const status = response?.data?.records[0]?.active;
			return status;
		}
		else {
			dispatch( ACTIONS.statusApiRequestFailure(response?.data) );
			showError(`Ha ocurrido un error al comunicarse con las fuentes de información externas. Por favor intente nuevamente más tarde.` );
			return response;
		}

	};
};