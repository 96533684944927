import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Form, Row, Col, Button, Table, Alert, FormControl, Spinner } from 'react-bootstrap';
import ActionIcon from 'src/components/general/ActionIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faBroom, faPlusCircle, faCheck, faMinusCircle, faExclamationTriangle, faSave, faFilter, faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { DELIVERY_ORDER_LIST } from 'src/utils/constants';
import FormFieldError from 'src/components/general/FormFieldError';
import { showError, showSuccess } from 'src/redux/globalData/globalDataActionCreator';
import { getFundRequestsListData, getFundRequestsListIsFetching } from 'src/redux/fundRequests/fundRequestsReducer'
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { tryListFundRequests } from 'src/redux/fundRequests/fundRequestsActionCreator'
import { formatterPeso, getCurrentYear, getClearObject } from 'src/utils/utils'
import { clearListFundRequestsData } from 'src/redux/fundRequests/fundRequestsActions';
import { tryListDeliveryOrder, tryPostDeliveryOrder } from 'src/redux/deliveryOrder/deliveryOrderActionCreator'
import { getDeliveryOrderCreateIsFetching, getDeliveryOrderListData, getDeliveryOrderListIsFetching } from 'src/redux/deliveryOrder/deliveryOrderReducer'
import AppLoading from 'src/components/common/AppLoading';
import { DropdownList } from 'react-widgets';
import { mFormat } from 'src/utils/utils';
import { getAdministrativeDocumentAvailablesData } from 'src/redux/administrativeDocument/administrativeDocumentReducer';
import { clearAdministrativeDocumentAvailablesData } from 'src/redux/administrativeDocument/administrativeDocumentActions';
import { isNotEmptyArray } from 'src/services/validationService';
import { clearCreateDeliveryOrderData } from 'src/redux/deliveryOrder/deliveryOrderActions';
import { AdministrativeServiceData, administrativeService } from 'src/utils/administrativeServiceUtils';
import { faInfoCircle } from '../../../../node_modules/@fortawesome/free-solid-svg-icons/index';

const DeliveryOrderNewPage = () => {
	const dispatch = useDispatch();
	const { register, handleSubmit, errors, trigger } = useForm({ shouldFocusError: true });

	const todayDate = moment(new Date()).format('YYYY-MM-DD');
	const [servicio, setServicio] = useState();
	const messagesDropDown = { emptyFilter: 'No hay resultados', emptyList: 'No hay resultados' };

	const administrativeDocumentAvailablesData = useSelector(state => getAdministrativeDocumentAvailablesData(state)?.records);

	const deliveryOrderFindLastIsFetching = useSelector(state => getDeliveryOrderListIsFetching(state));
	const deliveryOrderFindLastData = useSelector(state => getDeliveryOrderListData(state)?.records?.[0]?.number);
	const deliveryOrderFindLastNumberSetup = deliveryOrderFindLastData ? deliveryOrderFindLastData + 1 : undefined;
	const administrativeServiceData = AdministrativeServiceData();

	// Fund Request selected
	const [fundRequestSelected, setFundRequestSelected] = useState(undefined);
	const onClickDeliveryOrderSelected = item => {
		setFundRequestSelected(item);
	};
	const onClickRemoveDeliveryOrderSelected = item => {
		setFundRequestSelected(undefined);
	};	
	const disabledCondition = !fundRequestSelected;

	const onFormSubmit = data => {
		const params = {
			number: data?.numberDeliveryOrder,
			deliveryOrderDate: data?.numberDeliveryOrderDate,
			description: data?.description,
			year: data?.fiscalYearId,
			fundRequest: { id: fundRequestSelected?.id },
			administrativeDocument:
			{
				year: fundRequestSelected?.administrativeDocument?.year,
				codeOrganism: fundRequestSelected?.administrativeDocument?.codeOrganism,
				number: fundRequestSelected?.administrativeDocument?.number,
			}
		}
		if (fundRequestSelected != undefined) {
			const clearParams = getClearObject(params);
			dispatch(tryPostDeliveryOrder(clearParams)).then(
				response => {
					if (response?.status == 201) {
						showSuccess('Se creo correctamente la orden de entrega');
						dispatch(push(DELIVERY_ORDER_LIST));
					} else {
						showError(response?.data?.message);
					}
				}
			);
		} else {
			showError('Debe seleccionar al menos un pedido de fondo');
		}
	};

	// Validations
	const numberDeliveryOrderRequiredMsg = 'Debe ingresar el número de la orden de entrega';
	const dateDeliveryOrderRequiredMsg = 'Debe ingresar una fecha válida';

	// Form data
	const numberDeliveryOrderValidationObj = { required: numberDeliveryOrderRequiredMsg };

	const dateDeliveryOrderValidationObj = { 
		required: dateDeliveryOrderRequiredMsg,
		validate: val => {
			return fundRequestSelected && fundRequestSelected.fundRequestDate > val?'La fecha es anterior al pedido de fondo seleccionado':null;
		} 
	};

	//Pedidios de fondos
	const foundRequestList = useSelector(state => getFundRequestsListData(state)?.records);
	const foundRequestListIsFitching = useSelector(state => getFundRequestsListIsFetching(state))

	const globalSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));
	const deliveryOrderCreateIsFetching = useSelector(state => getDeliveryOrderCreateIsFetching(state));

	const defaultParams = {
		state: "ACTIVO_PENDIENTE_DE_AUTORIZACION",
		number: "",
		year: "",
		description: "",
		token: token
	}

	//Filtro Número
	const onChangeFilterNumber = event => {
		const value = event?.target?.value;
		setNumberFilterQuery(value);
	};

	const [numberFilterQuery, setNumberFilterQuery] = useState("");
	const [yearFilterQuery, setYearFilterQuery] = useState("");
	const filterNumberRef = useRef(null);

	//Filtro Texto
	const onChangeFilterText = event => {
		const value = event?.target?.value;
		setTextFilterQuery(value);
	};
	
	const [textFilterQuery, setTextFilterQuery] = useState("");
	const filterTextRef = useRef(null);
	
	const clearFilters = () => {
		setNumberFilterQuery('');
		setYearFilterQuery('');
		setTextFilterQuery('');
		setServicio('');
		loadAllData(defaultParams)
	};
	
	const onClickCancelButton = () => {
		redirectToBankAccountList();
	};
	const redirectToBankAccountList = () => { dispatch(push(DELIVERY_ORDER_LIST)) };

	//filter Submit
	const onSubmitFormFilter = () => {
		if (!foundRequestListIsFitching) {
			const filterParams = {
				state: "ACTIVO_PENDIENTE_DE_AUTORIZACION",
				number: numberFilterQuery,
				year: yearFilterQuery,
				description: textFilterQuery,
				administrativeServiceId: servicio,
				token: token
			} 
			dispatch(clearListFundRequestsData());
			dispatch(tryListFundRequests(filterParams)); 
		}
    }

	const { cancel, token } = axios.CancelToken.source();

	const checkKeyDownFilter = (e) =>{
		if (e.nativeEvent.key === 'Enter' && e.nativeEvent.path.length > 17){
			onSubmitFormFilter();
			e.nativeEvent.preventDefault();
		}

	};

	const defaultParamsToFindLastDO = {
		size: 1,
		sort: 'number,desc',
	};

	const loadAllData = (params) => {
		dispatch(tryListDeliveryOrder(defaultParamsToFindLastDO));
		dispatch(tryListFundRequests(params)); 
	}
	
	useEffect(() => {
		loadAllData(defaultParams);
		return () => {
			dispatch(clearAdministrativeDocumentAvailablesData());
			dispatch(clearCreateDeliveryOrderData());
			dispatch(clearListFundRequestsData());
			cancel("No longer latest query");
		}
	}, []);

	return <>
			<Container fluid>
				<Card className='mb-5'>
                    <Card.Header className='d-flex justify-content-between'>
                        <h1 className="h6 mt-1 mb-0">Nueva Orden de Entrega</h1>
                        <a className='text-white' target="_blank" href='https://dev.kb.cgmisiones.gob.ar/docs/safi2/safi2-responsable-entrega-fondos/#ejecuci%C3%B3n-financiera--%C3%B3rdenes-de-entrega'>
                            <FontAwesomeIcon icon={faInfoCircle} className='mr-2' />
                            <small>Ver manual de uso</small> 
                        </a>
                    </Card.Header>
					<Card.Body>
					<h6 className='text-black-color mb-3'>Datos de la Orden de Entrega</h6>
						<span className='text-danger d-flex mandatory-label text-right font-weight-bold font-italic'>
							<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
						Obligatorio
						</span>
						
						<Form onSubmit={handleSubmit(onFormSubmit)} onKeyDown={(e) => checkKeyDownFilter(e)} className='text-black-color mt-5 mb-5'>
							<Form.Group className='text-black-color' as={Row}>
								<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
									<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
									Nro de Orden de Entrega
								</Form.Label>
								<Col sm='2'>
									{ deliveryOrderFindLastIsFetching || foundRequestListIsFitching
										?
										<Spinner animation='border' />
										:
										<Form.Control
											type="number"
											placeholder="Número"
											name="numberDeliveryOrder"
											defaultValue={deliveryOrderFindLastNumberSetup}
											ref={register(numberDeliveryOrderValidationObj)}
											id="numberDeliveryOrderId" 
										/>
									}
									<FormFieldError errors={errors?.numberDeliveryOrder} />
								</Col>
								<Col sm='2'>
									<Form.Control
										type='number'
										ref={register}
										name='fiscalYearId'
										id='globalSelectedPeriod'
										value={globalSelectedPeriod?.year}
										readOnly />
								</Col>
							</Form.Group>

							<Form.Group className='text-black-color' as={Row}>
									<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
										<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
										Fecha
									</Form.Label>
									<Col sm='4'>
										<Form.Control
											name='numberDeliveryOrderDate'
											id='numberDeliveryOrderDate'
											ref={register(dateDeliveryOrderValidationObj)}
											type='date'
											defaultValue={todayDate}
											max={todayDate}
										/>
										<FormFieldError errors={errors?.numberDeliveryOrderDate} />
									</Col>
							</Form.Group>
							
							<Row className='ml-2 mt-5' >
								<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger' />
								Pedido de Fondo seleccionado
							</Row>
							<Table striped hover size='sm' responsive>
								<thead>
									<tr size='lg'>
										<th className='text-center text-white align-middle py-3' width="10%">Expediente</th>
										<th className='text-center text-white align-middle' width="10%">Servicio Administrativo</th>
										<th className='text-center text-white align-middle' width="10%">Fecha</th>
										<th className='text-center text-white align-middle' width="10%">Pedido de fondo Nro</th>
										<th className='text-center text-white align-middle' width="30%">Descripción</th>
										<th className='text-center text-white align-middle' width="10%">Importe</th>
										<th className='text-center text-white align-middle' width="10%">Acciones</th>
									</tr>
								</thead>
								<tbody className='text-black-color'>
									{
										fundRequestSelected ?
											<tr>
												<td className='text-center align-middle maxTruncate'>
													{fundRequestSelected?.administrativeDocument?.codeOrganism + ' - ' + fundRequestSelected?.administrativeDocument?.number + ' / ' +  fundRequestSelected?.administrativeDocument?.year }
												</td>
												<td className='text-center align-middle maxTruncate'>
													{administrativeService(fundRequestSelected?.administrativeServiceId, administrativeServiceData)}
												</td>
												<td className='text-center align-middle truncate maxTruncate'>
													{mFormat(fundRequestSelected?.fundRequestDate)}
												</td>
												<td className='text-center align-middle truncate maxTruncate'>
													{fundRequestSelected?.number} / {fundRequestSelected?.year}
												</td>
												<td className='text-center align-middle maxTruncate'>
													{fundRequestSelected?.description}
												</td>
												<td className='text-center align-middle truncate maxTruncate'>
													{formatterPeso.format(fundRequestSelected?.amount)}
												</td>
												<td className='text-center align-middle truncate maxTruncate'>
													<ActionIcon size='sm' icon={faMinusCircle} id='adduser' toolTipText='Remover pedido fondo' className='text-danger mr-2' onClick={() => onClickRemoveDeliveryOrderSelected(fundRequestSelected)} />
												</td>
											</tr>
											:
											<tr>
												<td colSpan='7' className='text-center align-middle truncate maxTruncate'>
													{!foundRequestListIsFitching &&
														<Alert variant='info' className='mb-0'>
															<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
															No se ha seleccionado ningún pedido de fondo
														</Alert>
													}
												</td>
											</tr>
									}
								</tbody>
							</Table>

							<Row className='ml-2 mt-5' >
								Pedido de Fondos Disponibles
							</Row>
							<Table striped hover size='sm' responsive>
								<thead>
									<tr size='lg'>
										<th className='text-center align-middle text-white py-3' width="10%">Expediente</th>
										<th className='text-center align-middle text-white' width="10%">Servicio Administrativo</th>
										<th className='text-center align-middle text-white' width="10%">Fecha</th>
										<th className='text-center align-middle text-white' width="10%">Pedido de fondo Nro</th>
										<th className='text-center align-middle text-white' width="30%">Descripción</th>
										<th className='text-center align-middle text-white' width="10%">Importe</th>
										<th className='text-center align-middle text-white' width="10%">Acciones</th>
									</tr>
									<tr className='secondary'>
									<th></th>
									<th>
										<DropdownList
											placeholder='Seleccione un servicio...'
											data={administrativeServiceData}
											allowCreate='onFilter'
											name='administrativeServiceId'
											textField='displayName'
											valueField='id'
											filter='contains'
											value={servicio}
											className='w-100 text-black-color'
											onChange={value => setServicio(value.id)}
											id='administrativeServiceId'
											messages={messagesDropDown}
											selectIcon={<FontAwesomeIcon   icon={faChevronDown} className='text-black-color' />}
											searchIcon={<FontAwesomeIcon   icon={faSearch} className='text-black-color' />} >
										</DropdownList>
									</th>
										<th>
										</th>
										<th>
											<FormControl
												type='number'
												id='searchNumber'
												placeholder="Número"
												onChange={onChangeFilterNumber}
												value={numberFilterQuery}
												ref={filterNumberRef}
											/>
										</th>
										<th className='text-center' colSpan='1'>
											<FormControl
												type='text'
												id='searchText'
												placeholder="Descripción"
												onChange={onChangeFilterText}
												value={textFilterQuery}
												ref={filterTextRef}
											/>
										</th>
										<th></th>
										<th className='text-center align-middle'>
											<div className='d-flex justify-content-around'>
												<ActionIcon
													size="lg"
													id="search-button"
													className="btn-primary search-button text-white-color"
													toolTipText="Filtrar"
													icon={faFilter}
													onClick={onSubmitFormFilter}
												/>
												<ActionIcon
													size="lg"
													id="clean-filter"
													className="btn-primary clean-filter text-white-color"
													toolTipText="Limpiar filtros"
													icon={faBroom}
													onClick={()=> clearFilters()}
												/>
											</div>
										</th>
									</tr>
								</thead>
								<tbody className='text-black-color'>
									{
										foundRequestList ?
											foundRequestList.map(item => <tr key={item.id}>
												<td className='text-center align-middle maxTruncate'>
													{item?.administrativeDocument?.codeOrganism + ' - ' + item?.administrativeDocument?.number + ' / ' +  item?.administrativeDocument?.year}
												</td>
												<td className='text-center align-middle truncate maxTruncate'>
													{administrativeService(item?.administrativeServiceId, administrativeServiceData)}
												</td>
												<td className='align-middle text-center'>
													{mFormat(item.fundRequestDate)}
												</td>
												<td className='align-middle text-center'>
													{item.number} / {item.year}
												</td>
												<td className='align-middle text-center maxTruncate'>
													{item.description}
												</td>
												<td className='text-right'>
													{formatterPeso.format(item?.amount)}
												</td>

												<td className='align-middle text-center'>
													{fundRequestSelected == item ?
														<ActionIcon   icon={faCheck} id='adduser' toolTipText='Pedido de fondo seleccionado' onClick={() => onClickDeliveryOrderSelected(item)} />
														:
														<ActionIcon   icon={faPlusCircle} id='adduser' toolTipText='Asignar pedido fondo' onClick={() => onClickDeliveryOrderSelected(item)} />
													}

												</td>
											</tr>)
											:
											<tr>
												<td colSpan='7' className='text-center'>
													{!foundRequestListIsFitching &&
														<Alert variant='info' className='mb-0'>
															<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
															No hay registros
														</Alert>
													}
												</td>
											</tr>
									}
								</tbody>
							</Table>
							<Form.Group className='mt-4 d-flex justify-content-around text-white-color'>
								<Button className='text-white-color' variant='primary' size='lg' onClick={onClickCancelButton}>
									Cancelar
								</Button>{' '}
								{!deliveryOrderCreateIsFetching &&
									<Button className='text-white-color' type='submit' variant='success' size='lg' disabled={isNotEmptyArray(administrativeDocumentAvailablesData) || disabledCondition} >
										Guardar
										<FontAwesomeIcon className='ml-3' icon={faSave} />
									</Button>
								}
							</Form.Group>
						</Form>
					</Card.Body>
				</Card>
				<AppLoading isLoading={deliveryOrderCreateIsFetching || foundRequestListIsFitching || deliveryOrderFindLastIsFetching} />
			</Container>
	</>
};

export default DeliveryOrderNewPage;